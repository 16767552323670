import React, { useState, useEffect, useRef } from "react";

import logo from "../images/voyage.png";
import instagramImg from "../images/sc4.svg";
import FacebookImg from "../images/sc1.svg";
import whatsappImg from "../images/sc3.svg";
import franceIcon from "../images/france.png";
import IndiaIcon from "../images/india.png";
import EmailIcon from "../images/email.png";
// import linkedinImg from "../images/sc2.svg";
// import Atchivementimg from "../images/achievements.webp";
// import SdgImg from "../images//SDG.webp";

const Footer = () => {
  return (
    <footer className={`p-4  shadow md:px-6 md:py-8 bg-black`}>
      {/* <hr className="my-6 border-lightGray sm:mx-auto dark:border-gray lg:my-8" /> */}

      <div className="grid sm:grid-cols-1  md:grid-cols-5 gap-2 flex ">
        <div className="flex md:items-start sm:items-center flex-col md:col-span-2  ">
          <div className=" sm:flex sm:flex-col md:flex-row lg:flex-row md:ml-5 mt-2 sm:ml-2 items-center justify-center  ">
            <div className=" flex justify-center items-center">
              <img className="w-20" src={logo} alt="Kapreign Logo" />
            </div>
            <div className="  text-white sm:ml-0 md:ml-5">
              Luxury Voyage Wellness
            </div>
          </div>

          <div className="md:text-sm sm:text-xl text-lightGray md:ml-6 ml-0">
            {/* Collaborate for your Digital Experience */}
          </div>
          <div className="flex flex-row  justify-center mt-2 sm:ml-2 md:ml-2">
            <a
              href="https://www.instagram.com/luxuryvoyagewellness"
              className="ml-4 w-5"
            >
              <img src={instagramImg} alt="Instagram" className="w-5" />
            </a>

            <a href="https://wa.me/7907226404" className="ml-4 w-5">
              <img src={whatsappImg} alt="WhatsApp" className="w-5" />
            </a>
            <a href="https://www.facebook.com/kapreign" className="ml-4 w-5">
              <img src={FacebookImg} alt="Facebook" className="w-5" />
            </a>
          </div>
          <span className="block md:text-sm sm:text-xl text-lightGray sm:text-center md:ml-6 mt-2 sm:ml-0">
            © 2024 Luxury Voyage Wellness . All Rights Reserved.
          </span>
          <div className=" md:text-sm sm:text-xl text-lightGray sm:text-center md:ml-6 mt-2 sm:ml-0">
            Privacy policy | Terms of service
          </div>
          <div
            href="https://Kapreign.com/"
            className=" md:text-sm sm:text-xl text-lightGray sm:text-center md:ml-6 mt-2 sm:ml-0"
          >
            Developed By Kapreign
          </div>

          <div className="flex md:ml-6 mt-2 sm:ml-0 ">
            {/* <img
              src={Atchivementimg}
              alt="Atchivementimg"
              className="w-40 mr-4"
            /> */}
            {/* <img src={SdgImg} alt="SdgImg" className="w-28" /> */}
          </div>
        </div>

        <div>
          <ul className="text-sm text-lightGray flex flex-col text ml-4 md:text-left sm:text-center ">
            <li className="md:text-sm sm:text-xl font-bold text-white">
              Medical Spa
            </li>
            <li>
              <div
                href="/sha-wellness"
                className=" hover:underline md:text-sm sm:text-xl"
              >
                Sha Wellness
              </div>
            </li>
            <li>
              <div
                href="/ananda-himalaya"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Ananda Himalaya
              </div>
            </li>
            {/* <li>
              <div
                href="/BRENNERS-PARK-HOTEL-AND-SPA"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Brenners Park Hotel and SPA
              </div>
            </li> */}
            <li>
              <div
                href="/LEELA-RAVIZ"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Leela Raviz
              </div>
            </li>
            <li>
              <div
                href="/Amaltamara"
                className=" hover:underline md:text-sm sm:text-xl"
              >
                Amal Tamara
              </div>
            </li>
          </ul>
        </div>

        <div>
          <ul className="text-sm text-lightGray flex flex-col text ml-4 md:text-left sm:text-center ">
            <li className="md:text-sm sm:text-xl font-bold text-white">
              Resort
            </li>
            <li>
              <div
                href="/jungleResorts"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Luxury Jungle Resorts
              </div>
            </li>
            <li>
              <div
                href="/Hill-Resorts"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Hill Resorts
              </div>
            </li>
            <li>
              <div
                href="/Rain-Forest-resorts"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Rain Forest Resorts
              </div>
            </li>
            <li>
              <div
                href="/Vaithri-resorts"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Vaithri Resorts
              </div>
            </li>
            <li>
              <div
                href="/Honeymoon-resorts"
                className="md:text-sm sm:text-xl hover:underline"
              >
                Honeymoon Resorts
              </div>
            </li>
          </ul>
        </div>

        <div>
          <ul className="text-sm text-lightGray flex flex-col text ml-4 md:text-left sm:text-center ">
            <li className="md:text-sm sm:text-xl font-bold text-white mt-2">
              Contact
            </li>
            <li className="md:text-sm sm:text-xl flex flex-row  items-center justify-center md:justify-start">
              <img src={franceIcon} alt="franceIcon" className="w-5 h-5" />
              <div className="ml-2"> +33 6 60 08 97 02</div>
            </li>
            <li className="md:text-sm sm:text-xl flex  items-center  justify-center md:justify-start">
              <img src={IndiaIcon} alt="IndiaIcon" className="w-5 h-5" />
              <div className="ml-2"> +91 80 86 08 04 40</div>
            </li>
            <li className="md:text-sm sm:text-xl flex  items-center  justify-center md:justify-start">
              <img src={IndiaIcon} alt="IndiaIcon" className="w-5 h-5" />
              <div className="ml-2">+91 97 47 44 78 33</div>
            </li>
            <li className="md:text-sm sm:text-xl flex  items-center  justify-center md:justify-start">
              <img src={EmailIcon} alt="EmailIcon" className="w-5 h-5" />
              <div className="ml-2"> info@luxuryvoyagewellness.life</div>
            </li>
          </ul>
        </div>
      </div>

      {/* <hr className="my-6 border-lightGray sm:mx-auto dark:border-gray lg:my-8" /> */}
    </footer>
  );
};
export default Footer;
