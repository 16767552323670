import React, { Component } from "react";
import View from "./Components/View";
import image1 from "../../images/leela/leela1.jpg";
import image2 from "../../images/leela/leela2.jpg";
import image3 from "../../images/leela/leela3.jpg";
import image4 from "../../images/leela/leela4.jpg";
import Programs from "./Components/Programs";
import leelalife1 from "../../images/leela/leelalife1.jpg";
import leelalife2 from "../../images/leela/leelalife2.jpg";
import leelalife3 from "../../images/leela/leelalife3.jpg";
import leelawellness from "../../images/leela/leelawellness.jpg";
import leelastress from "../../images/leela/leelastress.jpg";
// import HotelDetails from "./Components/HotelDetails";
import leelabeach from "../../images/leela/leelabeach.jpg";
import leelagarden from "../../images/leela/leelagarden.jpg";
import leelaclub from "../../images/leela/leelaclub.jpg";
import leeladuplex from "../../images/leela/leeladuplex.jpg";
import leelapresidential from "../../images/leela/leelapresidential.jpg";

const NavData1 = [
  "Lifestyle Package",
  "Wellness Packages",
  "Therapeutic Packages",
  "Essence of Wellness"
];
const NavItem = [
  "Beach View",
  "Garden View",
  "Club Room",
  "Club Duplex Suite",
  "Presidential Suite"
];

const LeelaRaviz = () => {

  return (
    <div>
      <View
        heading="LEELA RAVIZ"
        videoID="e4jqMgG7rXA"
        image1={image1}
        image2={image2}
        image3={image3}
        image4={image4}
        programs={
          <Programs
            NavData={NavData1}
            tabs={[
              {
                id: 0, title: 'Lifestyle Package',
                content:
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    {/* Section 1 */}
                    <div className="flex flex-col justify-center">
                      <div className="text-center  font-bold font-sans ">
                        SKIN CARE-BEAUTY THERAPY DURATION: 7 -14 DAYS
                      </div>
                      <p className="mt-5 text-justify">
                        A relaxation and rejuvenation plan for the Skin, using herbal oils and
                        medicines, which nourishes and tones the skin, underlying structures and
                        body tissues. Treatments provide release of day-to-day stress and calm
                        the mind and relax the body. Also included are massages and treatments
                        that encourage purgation and purify the blood which is very important in
                        maintaining a healthy skin. Due to the intricacy of the program a
                        detoxification diet is recommended for success.
                      </p>
                      <div className="text-center font-bold">TREATMENT INCLUSIONS</div>
                      <ul className="text-justify ml-4" style={{ listStyleType: 'disc' }}>
                        <li>Mukhalepam with varnya herbs</li>
                        <li>Ksheeradhara</li>
                        <li>Kashayadhara</li>
                        <li> Anjanam</li>
                        <li> Udhwarthanam</li>
                        <li> Ksheeradhoomam</li>
                        <li> Alepam-Whole body pack</li>
                        <li> Dinacharya</li>
                        <li> Thanusree</li>
                        <li> Nasyam</li>
                        <li> Virechanam-Induced purgation</li>
                        <li> Vasthi- Oil enema</li>
                        <li> Rakthamoksham-Blood Letting</li>
                        <li> Njavara Pinda Swedam</li>
                        <li> Therapeutic Yoga for skin health</li>

                      </ul>
                    </div>
                    <img className="w-full" src={leelalife1} alt="Skin Care Beauty Therapy" />

                    {/* Section 2 */}
                    <img className="w-full" src={leelalife2} alt="Rejuvenate" />
                    <div className="flex flex-col justify-center">
                      <div className="font-bold">REJUVENATE DURATION: 7 DAYS</div>
                      <p className="text-justify">
                        One whose dosha (3 bio-energies), Agni (metabolic fire),
                        Dhathu (tissues), mala (waste products), are in
                        equilibrium and who has cheerful Atma (Soul), Indriya
                        (sense organs) and manas (mind) is considered as
                        healthy. This package focuses on the preventive aspect
                        of the body from illness and a relaxation and
                        rejuvenation plan for the body and mind using medicated
                        oils and medicines which nourishes the body tissues.
                        Treatments provide release of day-to-day stress and calm
                        the mind and relax the body. Also included are massages
                        and treatments that encourage purgation and purify the
                        blood. Due to the intricacy of the program a
                        detoxification diet based on the individual body
                        constitution is recommended for success. Rasayana
                        Chikitsa (Rejuvenation therapy) comes under the “Swastha
                        Vritha” or preventive program in Ayurveda. This
                        treatment program helps to rejuvenate, recharge and
                        revitalize your body and mind.
                      </p>
                      <div className="text-center font-bold">TREATMENT INCLUSIONS</div>
                      <ul className="text-justify ml-4" style={{ listStyleType: 'disc' }}>
                        <li> Abhyanga Snana</li>
                        <li> Shirodhara</li>
                        <li>Choorna Pindasweda</li>
                        <li>Pathra Pindasweda</li>
                        <li>Ksheeradhara</li>
                        <li>Njavara Pindasweda</li>
                        <li>Chavuttithirummu</li>
                        <li>Kalari</li>
                        <li>Dinacharya</li>
                        <li>Thanusree</li>
                        <li>Virechanam – Induced purgation</li>
                        <li>Vasthi – Oil enema</li>
                        <li>Therapeutic Yoga and Meditation for rejuvenation</li>
                      </ul>
                    </div>

                    {/* Section 3 */}
                    <div className="flex flex-col justify-center">
                      <div className="text-center font-bold">DE-STRESS DURATION: 7 DAYS</div>
                      <p className="text-justify">
                        This package focuses on the preventive aspect of the
                        body from illness and a relaxation and rejuvenation plan
                        for the body and mind using medicated oils and medicines
                        which nourishes the body tissues. Treatments provide
                        release of day-to-day stress and calm the mind and relax
                        the body. Also included are massages and treatments that
                        encourage purgation and purify the blood. A body and
                        mind balancing diet based on the individual body
                        constitution is recommended for better results.
                      </p>
                      <div className="text-center font-bold">TREATMENT INCLUSIONS</div>
                      <ul className="text-justify ml-4" style={{ listStyleType: 'disc' }}>
                        <li> Abhyanga Snana</li>
                        <li>Shirodhara</li>
                        <li>Ksheeradhara</li>
                        <li>Thakradhara</li>
                        <li>Njavara Pindasweda</li>
                        <li>Thalapothichil</li>
                        <li>Chavuttithirummu</li>
                        <li>Kalari</li>
                        <li>Virechanam</li>
                        <li>Therapeutic Yoga and Meditation for de stress</li>
                      </ul>
                    </div>
                    <img className="w-full" src={leelalife3} alt="De-Stress" />
                  </div>
              },
              {
                id: 1, title: 'Wellness Packages',
                content:
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    {/* Section 1 */}
                    <div className="flex flex-col justify-center">
                      <h3 className="text-center  font-bold">
                        WEIGHT LOSS DURATION: 14 – 21 DAYS
                      </h3>
                      <p className="text-justify">
                        The program includes weight management treatments based on the principle that losing weight is not about starvation but balancing the fat metabolism. {/* ...Remaining content... */}
                      </p>
                      <h3 className="text-center  font-bold">TREATMENT INCLUSIONS</h3>
                      <ul className="text-justify ml-4" style={{ listStyleType: 'disc' }}>
                        <li> Udhwarthanam</li>
                        <li> Dhanyamladhara</li>
                        <li> Choorna Pindaswedam</li>
                        <li> Kalari</li>
                        <li> Virechanam – Medicated Purgation</li>
                        <li>
                          {" "}
                          Vasthi – Specific Weight loss Kashaya (Decoction) Enema
                        </li>
                        <li>
                          {" "}
                          Special cardio work outs by trained Gym Instructors
                        </li>
                        <li> Therapeutic Weight Loss Yoga </li>
                      </ul>
                    </div>
                    <img className="w-full" src={leelawellness} alt="Weight Loss Program" />
                  </div>

              },
              {
                id: 2, title: 'Therapeutic Packages',
                content:
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    {/* Section 1 */}
                    <div className="flex flex-col justify-center">
                      <h3 className="text-center  font-bold">
                        SPINE CARE DURATION: 14 – 21 DAYS
                      </h3>
                      <p className="text-justify">
                        This package is to prevent spinal disorders and to cure low back ache, inter vertebral disc prolapse, Sciatica, Cervical Spondylosis, Brachial neuralgia, and other associated ailments.
                      </p>
                      <p className="text-justify">
                        Special Package for IT Professionals Pain Management| De-stress |Management for WRULD – Special Package for IT Professionals.
                      </p>
                      <p className="text-justify">
                        Work-related neck and upper limb disorders (WRULDs) are impairments of bodily structures such as to a tendon, nerve, muscle, joint, bursa or the localized blood circulation system. {/* ...Remaining content... */}
                      </p>
                      <h3 className="text-center  font-bold ">TREATMENT INCLUSIONS</h3>
                      <ul className="text-justify ml-4" style={{ listStyleType: 'disc' }}>
                        <li>Abhyanga Snana</li>
                        <li>Shirodhara</li>
                        <li>Udhwarthanam</li>
                        <li>Njavara Pindaswedam</li>
                        <li>Ksheeradhara</li>
                        <li>Kashayadhara</li>
                        <li>Nasyam-Nasal Medication</li>
                        <li>Virechanam-Induced purgation </li>
                        <li>Vasthi-Both Oil and Decoction enema</li>
                        <li>Daily a session of Therapeutic Marma along with the usual treatment time.</li>
                        <li>Therapeutic Yoga and Meditation</li>
                        {/* ...Other list items... */}
                      </ul>
                    </div>
                    <img className="w-full" src={leelastress} alt="Spine Care Program" />
                  </div>

              },
              {
                id: 3, title: 'Essence of Wellness',
                content:
                  <div className="text-left">
                    <div className="text-center">
                      <h3 className="text-3xl font-bold mb-4">Our Signature Massage</h3>
                    </div>

                    <div className="my-4">
                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">INDIAN MAGIC</h6>
                        <p className="text-justify">
                          Begins with an invigorating sandalwood and turmeric scrub followed by a deep tissue massage with specialized oils blended with Aswagandha, Bala root, and Agaru herbs which help to rejuvenate both aching muscles and tired minds.
                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 90 Minutes <br />
                          <strong>Price</strong>: Rs.4500
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">SWEDISH MASSAGE</h6>
                        <p className="text-justify">
                          A popular, traditional massage designed to loosen parts of the body that are tense and stressed. It also increases circulation and improves your overall skin and muscle tone.
                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 60 Minutes <br />
                          <strong>Price</strong>: Rs.3800
                        </div>
                      </div>
                      <hr class="my-4" />

                      {/* Include similar sections for each massage type */}

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">AROMATHERAPY MASSAGE</h6>
                        <p className="text-justify">
                          This massage, which uses individually chosen essential oils, is a multi-sensory experience in relaxation. it is a complete face, scalp, and body massage that focuses on vital acupressure points. It strengthens the nervous systems and restores vitality   </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 60 Minutes <br />
                          <strong>Price</strong>: Rs.3800
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">SPORTS AND FITNESS MASSAGE</h6>
                        <p className="text-justify">
                          A massage that is useful before or after exercise. Its blend of essential oils like lavender rosemary and clove help to increase circulation and warm the muscles. It relieves aches and pains and prevents strains, so is ideal for those involved in sports and fitness or those who do strenuous physical work.

                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 60 Minutes <br />
                          <strong>Price</strong>: Rs.3800
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">REFLEXOLOGY</h6>
                        <p className="text-justify">
                          An ancient oriental method of massage, here pressure is applied to different points of the feet corresponding to individual parts of the body. This technique drains away stress and tension while restoring a natural flow of energy in the body.

                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 60 Minutes <br />
                          <strong>Price</strong>: Rs.3800
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">HOT STONE MASSAGE</h6>
                        <p className="text-justify">
                          Experience a deep sense of relaxation by this unique treatment that uses ergonomically crafted warm stones to manipulate the tired muscles.

                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 90 Minutes <br />
                          <strong>Price</strong>: Rs.4500
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">HEAD NECK AND SHOULDER MASSAGE</h6>
                        <p className="text-justify">
                          A stress relieving massage is done with warm herbal oil for the head neck and shoulder.


                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 30 Minutes <br />
                          <strong>Price</strong>: Rs.2000
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">BALINESE MASSAGE</h6>
                        <p className="text-justify">
                          This typical massage, originated in Bali, Indonesia, comprises stroking, stretching exercises and reflexology techniques with essential massage oils used in the process. It not only increases blood flow but also loosens muscles, eases pain and relaxes the body. It is a kind of deep massage aimed at working almost every single muscle in the body, done often to bring about a relaxed state on mind.




                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 60 Minutes <br />
                          <strong>Price</strong>: Rs.3800
                        </div>
                      </div>
                      <hr class="my-4" />

                      <div className="text-left mb-4">
                        <h6 className="text-lg font-bold">BODY POLISHES & SCRUBS</h6>
                        <p className="text-justify">
                          Exfoliate, renew and nourish your body with scrubs blended with indigenous herbs, sea salt and pure essential oils. Sugar and Tamarind Sparkling Body Polish Hydrating Sea Salt Crystal Rose bath scrub-30 minutes Marigold and Basil Sands scrub-30 minutes

                        </p>
                        <div className="mt-2">
                          <strong>Duration</strong>: 30 Minutes <br />
                          <strong>Price</strong>: Rs.2800
                        </div>
                      </div>
                      <hr class="my-4" />

                    </div>
                  </div>

              },

            ]}

          ></Programs>
        }

        HotelCardsData={[
          {
            id: 1,
            heading: 'Beach View',
            image: leelabeach,
            subHead: "These cliff-top rooms come to almost 500 sq ft in size. Beach View",
            content: 'These cliff-top rooms come to almost 500 sq ft in size. Located in the main block of the hotel, the Beach View Superior Rooms have private sundecks attached, which look out into spectacular views of the Kovalam shoreline. Sublime sunrises and resplendent sunsets are now just a short walk away.Adorned with the traditional Kerala décor, and leaning towards the architectural style of the land, these rooms are fitted with modern amenities like mini bar, posturepedic beds and walk-in wardrobe. Responsive climate control, high-speed internet access/Wi-Fi internet connectivity, an HD TV and in-room electronic safes, in-room dining, laundry, concierge services and doctor-on-call are other amenities you can look forward to in these rooms.',
            modalcontent: <div>

            </div>
          },
          {
            id: 2,
            heading: 'Garden View',
            image: leelagarden,
            subHead: "You could check into these rooms if privacy is one of your primary requirements. Garden View",
            content: 'You could check into these rooms if privacy is one of your primary requirements. Secluded from the main block of the hotel, these 475 sq ft rooms are located on the beach level. Private sundecks attached to these rooms open out to the well-manicured lawns in the hotel. Put on your sandals and step out into the sand—the beach is just a short walk away.  The Garden View Pavilion rooms come with their own secluded pool and lawns and look out at the verdant coconut groves around.    The rooms have a traditional Kerala décor, understated and neutral, which tastefully combines with the modern amenities like mini bar, a walk-in closet and posturepedic beds. Walk into this heaven and cut out the crowd of the world behind. Book that ideal holiday you have waited for all along.',
            modalcontent: <div>

            </div>
          },
          {
            id: 3,
            heading: 'Club Room',
            image: leelaclub,
            subHead: "Almost double the size of the Club Room Club Suite",
            content: 'Almost double the size of the Club Room, the Club Suites offer all the advantages of the Club and some more. The décor of the room comprises linear, minimalistic designs blended with a mix of contemporary chic and old-world charm. Each room features an extended work desk that looks out at the ocean ahead, a state- of-the-art LCD flat screen TV and DVD player with a choice of movies and music, wireless internet access and an electronic safe. To ensure a relaxed slumber experience, we have provided down feather pillows and duvets, as also a pillow-menu option, luxury posture supporting mattress and plush linen. With a five-feature bathroom that lets daylight stream in and twin-head massage showerheads, private sundeck that comes with its own sun bed, we assume the hardest part would be to step back into reality when you check out of these suites.',
            modalcontent: <div>

            </div>
          },
          {
            id: 4,
            heading: 'Club Duplex Suite',
            image: leeladuplex,
            subHead: "Enter our Duplex Suite and be mesmerised by the essence of its unhurried luxury Club Duplex Suite",
            content: 'Enter our Duplex Suite and be mesmerised by the essence of its unhurried luxury. This two-level suite features a living room and private balcony on one level, and an opulent bedroom on the next, looking out into the Arabian Sea. Wake up to the wafts of breeze blowing in from the Arabian Sea and head to the Club Café for breakfast. You may even choose to have breakfast in bed. While away time watching movies from a selection of DVDs, on request, and spend a languid evening enjoying hi-tea with your dear ones at the Living Room.Besides pick up and drop in our luxury sedan, guests in the suite have exclusive access to fine dining, spa and fitness centre, the ocean-rim infinity pool and library with cigar room. The stay in this 1291 sq ft suite comes with complimentary use of the clothes-pressing services as well as the gym and infinity pool.',
            modalcontent: <div>

            </div>
          },
          {
            id: 5,
            heading: 'Presidential Suite',
            image: leelapresidential,
            subHead: "The Presidential Suites comprise a two-bedroom suite with king-size beds, and a separate living and dining areas. Presidential Suite",
            content: 'The Presidential Suites comprise a two-bedroom suite with king-size beds, and a separate living and dining areas. The décor in the suites combine local tropical architecture and contemporary swank. The spaces are fitted with modern amenities like TV and DVD, coffee and tea maker, a well-stocked mini bar and a 24-hour personal butler for comfort. Dramatically perched on a rocky ledge, with private sun decks, these 1506 sq ft suites are a short walk from the beach. Strap on your sandals and head out to catch the most captivating sunsets. The beaches are relatively safe, and the waters, reassuringly warm. Guests can choose to have breakfast at the Club Café or opt for in-room dining. Evening high tea, complementary use of clothes- pressing services and the gym and ocean-rimmed infinity pool add to the living experience.',
            modalcontent: <div>

            </div>
          },
        ]}

      >
        <div className="">
          <div className=" ">
            <div className="">
              <div className="">
                <div className="bg-white text-black text-left p-5">
                  <div >
                    Guided by the philosophy of our Chairman and CEO, the
                    Raviz Group has set out on a course to present the
                    traditions, culture and hospitality of Kerala to the
                    visiting traveler on a platter.
                  </div>
                  <div className="mt-2">
                    Having set out on this endeavor, we aspire to bring alive
                    our philosophy of Celebrating India that will reflect
                    through all our products and services that defines the
                    fabric of the Raviz across the board.
                  </div>
                  <div className="mt-2">
                    While you enjoy the traditional and authentic Kerala
                    warmth that undoubtedly engulfs you as you walk into any
                    property, a contemporary presentation will place it in
                    perspective for you every step of this journey with us.
                    And we see this across properties already such as the
                    Raviz Resort & Spa Ashtamudi, a labour of love 12 years in
                    the making, the Raviz Resort & Spa Kadavu and the Leela
                    Raviz Kovalam–that hold the ethos in its very fabric.
                  </div>
                  <div className="mt-2">
                    Our properties are built with a focus on sustainable
                    models of products and services our services will be
                    nothing short of the best in class and comfort.
                  </div>
                  <div className="mt-2">
                    So sit back, relax, and enjoy the heavenly facilities
                    unrolled exclusively for you – here is the place where the
                    sky meets the water, as the horizon melts.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </View>
    </div>
  );
}
export default LeelaRaviz;
