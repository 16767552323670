import HomeScreen from "../Pages/Home";
import AnandaHimalaya from "../components/Medicalspa/AnandaHimalaya";
import Shawellness from "../components/Medicalspa/Shawellness";
import Brenners from "../components/Medicalspa/Brenners";
import LeelaRaviz from "../components/Medicalspa/LeelaRaviz";
import PalaceResorts from "../components/Resort/PalaceResorts";
import LuxuryJungleResort from "../components/Resort/LuxuryJungleResort";
import WildPlanetResort from "../components/Resort/WildPlanetResort";
import HillResorts from "../components/Resort/HillResorts";
import FogResorts from "../components/Resort/FogResorts";
import RainForestresorts from "../components/Resort/RainForestResort";
import VaithryResorts from "../components/Resort/VaithryResorts";
import HoneyMoonResort from "../components/Resort/HoneyMoonResort";
import KTDC from "../components/KTDC";
import AyurvedhaTreatments from "../components/Ayurvedha/AyurvedhaTreatments";
import Somatheeram from "../components/Medicalspa/Somatheeram";
import Amaltamara from "../components/Medicalspa/Amaltamara";

const IndexRoutes = {
  Home: {
    path: "/",
    name: "Home",
    key: "Home",
    component: HomeScreen,
    exact: true,
    isSecured: false,
  },
  Shawellness: {
    path: "/sha-wellness",
    name: "sha-wellness",
    key: "sha-wellness",
    component: Shawellness,
  },
  AnandaHimalaya: {
    path: "/ananda-himalaya",
    name: "ananda-himalaya",
    key: "ananda-himalaya",
    component: AnandaHimalaya,
  },
  BRENNERSPARK: {
    path: "/BRENNERS-PARK-HOTEL-AND-SPA",
    name: "BRENNERS-PARK-HOTEL-AND-SPA",
    key: "BRENNERS-PARK-HOTEL-AND-SPA",
    component: Brenners,
  },
  LeelaRaviz: {
    path: "/LEELA-RAVIZ",
    name: "LEELA-RAVIZ",
    key: "LEELA-RAVIZ",
    component: LeelaRaviz,
  },
  SomaTheeram: {
    path: "/Somatheeram",
    name: "Somatheeram",
    key: "Somatheeram",
    component: Somatheeram,
  },
  Amaltamara: {
    path: "/Amaltamara",
    name: "Amaltamara",
    key: "Amaltamara",
    component: Amaltamara,
  },
  PalaceResorts: {
    path: "/PalaceResorts",
    name: "PalaceResorts",
    key: "PalaceResorts",
    component: PalaceResorts,
  },
  jungleResorts: {
    path: "/jungleResorts",
    name: "jungleResorts",
    key: "jungleResorts",
    component: LuxuryJungleResort,
  },
  WildPlanet: {
    path: "/WildPlanet",
    name: "WildPlanet",
    key: "WildPlanet",
    component: WildPlanetResort,
  },

  HillResorts: {
    path: "/Hill-Resorts",
    name: "HillResorts",
    key: "HillResorts",
    component: HillResorts,
  },
  fogresorts: {
    path: "/fog-resorts",
    name: "fogresorts",
    key: "fogresorts",
    component: FogResorts,
  },
  RainForestresorts: {
    path: "/Rain-Forest-resorts",
    name: "RainForestresorts",
    key: "RainForestresorts",
    component: RainForestresorts,
  },
  Vaithriresorts: {
    path: "/Vaithri-resorts",
    name: "Vaithriresorts",
    key: "Vaithriresorts",
    component: VaithryResorts,
  },
  Honeymoonresorts: {
    path: "/Honeymoon-resorts",
    name: "Honeymoonresorts",
    key: "Honeymoonresorts",
    component: HoneyMoonResort,
  },
  KTDC: {
    path: "/KTDC",
    name: "KTDC",
    key: "KTDC",
    component: KTDC,
  },
  Ayurveda: {
    path: "/Ayurveda",
    name: "Ayurveda",
    key: "Ayurveda",
    component: AyurvedhaTreatments,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   key: "about",
  //   component: HomeScreen,
  // },
  // {
  //   path: "/sha-wellness",
  //   name: "sha-wellness",
  //   component: Shawellness,
  // },
  // {
  //   path: "/ananda-himalaya",
  //   name: "ananda-himalaya",
  //   component: AnandaHimalaya,
  // },
  // {
  //   path: "/BRENNERS-PARK-HOTEL-AND-SPA",
  //   name: "BRENNERS-PARK-HOTEL-AND-SPA",
  //   component: Brenners,
  // },
  // {
  //   path: "/LEELA-RAVIZ",
  //   name: "LEELA-RAVIZ",
  //   component: LeelaRaviz,
  // },
  // {
  //   path: "/AyurvedaLearning",
  //   name: "AyurvedaLearning",
  //   component: AyurvedaLearning,
  // },
  // {
  //   path: "/Yoga",
  //   name: "Yoga",
  //   component: Yoga,
  // },
  // {
  //   path: "/Kalarippayattu",
  //   name: "Kalarippayattu",
  //   component: Kalarippayattu,
  // },
  // {
  //   path: "/TherpyAqua",
  //   name: "TherpyAqua",
  //   component: TherpyAqua,
  // },
  // {
  //   path: "/Rajah",
  //   name: "Rajah",
  //   component: Rajah,
  // },
  // {
  //   path: "/YogaVillage",
  //   name: "YogaVillage",
  //   component: YogaVillage,
  // },
  // {
  //   path: "/PalaceResorts",
  //   name: "PalaceResorts",
  //   component: PalaceResorts,
  // },
  // {
  //   path: "/fog-resorts",
  //   name: "fogresorts",
  //   component: FogResorts,
  // },
  // {
  //   path: "/Rain-Forest-resorts",
  //   name: "rainforestresorts",
  //   component: RainForestResort,
  // },
  // {
  //   path: "/Vaithri-resorts",
  //   name: "VaithryResorts",
  //   component: VaithryResorts,
  // },
  // {
  //   path: "/Econatural-resorts",
  //   name: "/Econatural",
  //   component: Econatural,
  // },  {
  //   path: "/Honeymoon-resorts",
  //   name: "/HoneyMoonResort",
  //   component: HoneyMoonResort,
  // },
  // {
  //   path: "/jungle-resorts",
  //   name: "/LuxuryJungleResort",
  //   component: LuxuryJungleResort,
  // },
  // {
  //   path: "/Hill-Resorts",
  //   name: "/HillResorts",
  //   component: HillResorts,
  // },
  // {
  //   path: "/KTDC",
  //   name: "/KTDC",
  //   component: KTDC,
  // },
  // {
  //   path: "/Ayurveda",
  //   name: "Ayurveda",
  //   component: AyurvedhaTreatments,
  // },
  // {
  //   path: "/watsu",
  //   name: "watsu",
  //   component: watsu,
  // },
  // {
  //   path: "/WildPlanet",
  //   name: "WildPlanet",
  //   component: WildPlanet,
  // },
};

export default IndexRoutes;
