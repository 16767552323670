import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/voyage.png';
import '../styles/tailwind.css';
import { useNavigate } from "react-router-dom";

// Mobile Menu Component
const MobileMenu = ({ navData, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-lightBlack text-white flex flex-col justify-center items-center z-50 overflow-y-auto">
      <ul className="p-4 w-full space-y-4">
        {navData.map((item, index) => (
          <li key={index} className="my-2 text-xl font-bold">
            <a href={item.link} onClick={onClose}>
              {item.label}
            </a>
            {item.dropdownItems && (
              <ul className="mt-2">
                {item.dropdownItems.map((dropdownItem, subIndex) => (
                  <li key={subIndex} className="my-1">
                    <a href={dropdownItem.link} onClick={onClose}>
                      {dropdownItem.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const Dropdown = ({ items, isOpen, index }) => {
    return (
      isOpen && (
        <ul ref={dropdownRef}
          onMouseLeave={() => handleDropdownClick(index)}
          className="absolute  py-2 w-40  bg-white rounded-lg shadow-md z-10">
          {items.map((item, index) => (
            <a key={index} href={item.link} className="font-Frank block px-4 py-2 text-sm text-black hover:bg-gray ">
              {item.label}
            </a>
          ))}
        </ul>
      )
    );
  };

  const navData = [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Medical Spa',
      dropdownItems: [
        { label: 'Sha Wellness', link: '/sha-wellness' },
        { label: 'Ananda Himalaya', link: '/ananda-himalaya' },
        // { label: 'Brenners Park Hotel and SPA', link: '/BRENNERS-PARK-HOTEL-AND-SPA' },
        { label: 'Leela Raviz', link: '/LEELA-RAVIZ' },
        { label: 'Somatheeram', link: '/Somatheeram' },
        { label: 'Amal Tamara', link: '/Amaltamara' }

        // Add more dropdown items for Medical Spa
      ],
    },
    {
      label: 'Ayurveda',
      dropdownItems: [
        { label: 'Ayurveda Treatments', link: '/Ayurveda' },


        // Add more dropdown items for Resort
      ],
    },
    {
      label: 'Palace Resorts',
      link: '/PalaceResorts',
    },
    {
      label: 'Resort',
      dropdownItems: [
        { label: 'Luxury Jungle Resorts', link: '/WildPlanet' },
        { label: 'Hill Resorts', link: '/fog-resorts' },
        // { label: 'Hill Resorts', link: '/Hill-Resorts' },
        // { label: 'Rain Forest Resorts', link: '/Vaithri-resorts' },
        // { label: 'Rain Forest Resorts', link: '/Rain-Forest-resorts' },
        { label: 'Vaithri Resorts', link: '/Vaithri-resorts' },
        // { label: 'Honeymoon Resorts', link: '/Honeymoon-resorts' },

        // Add more dropdown items for Resort
      ],
    },
    {
      label: 'Ktdc',
      link: '/KTDC',
    },

    // Add more navigation items as needed
  ];

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleBookNowClick = () => {
    const element = document.getElementById('contact');
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleButtonClick = (path) => {
    //  navigate to the specified path
    navigate(path);
  };

  return (

    <nav className={`flex items-center justify-between flex-wrap p-6 transition duration-300 ease-in-out shadow-none ${!isScrolled && !isOpen ? 'bg-lightTransparent' : 'bg-white'}`}>
      <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
        <img src={Logo} className="w-100 h-10 mr-2" alt="Logo" />
        <div className={`text-xl font-Frank font-bold ${!isScrolled && !isOpen ? 'text-white' : 'text-black'} `}>Luxury Voyage Wellness</div>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
        >
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full justify-end  block flex-grow lg:flex lg:items- lg:w-auto ${isOpen ? "block" : "hidden"}`}
      >

        <div className=" md:flex space-x-4">
          {navData.map((item, index) => (
            <div key={index} className="relative mb-2 md:mb-0">
              {item.dropdownItems ? (
                <button
                  type="button"
                  // className="text-white focus:outline-none"
                  className={`focus:outline-none text-sm font-Frank   ${!isScrolled && !isOpen ? 'text-white' : 'text-black'} `}
                  onMouseOver={() => handleDropdownClick(index)}

                >
                  {item.label}
                  {/* <svg
                    className="w-3 h-3 inline-block "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg> */}
                </button>
              ) : (
                <button
                  type="button"
                  className={`font-Frank text-sm ${!isScrolled && !isOpen ? 'text-white' : 'text-black'}`}
                  onClick={() => handleButtonClick(item.link)} // Use handleButtonClick to navigate
                >
                  {item.label}
                </button>
              )}
              <div className='flex justify-center '>
                {item.dropdownItems && (
                  <Dropdown items={item.dropdownItems} isOpen={activeDropdown === index} index={index} />
                )}
              </div>
            </div>
          ))}
          <div className="relative mb-2 md:mb-0">
            <button
              type="button"
              className={`focus:outline-none text-sm bg-green font-Frank p-1 rounded-lg   ${!isScrolled && !isOpen ? 'text-white' : 'text-black'} `}
              onClick={handleBookNowClick}

            >
              Book Now

            </button>
          </div>
        </div>

      </div>
    </nav>
    // <nav className={`p-4 transition duration-300 ease-in-out shadow-none ${isScrolled ? 'bg-lightBlack' : 'bg-lightTransparent'}`}>
    //   <div className="max-w-7xl mx-auto">
    //     <div className="flex justify-between items-center">
    //       <div className="flex items-center">
    //         <a href="#">
    //           <img src={Logo} alt="Luxury Voyage Wellness" className="w-auto h-10" />
    //         </a>
    //       </div>
    //       <div className="hidden md:flex space-x-4">
    //         {navData.map((item, index) => (
    //           <div key={index} className="relative mb-2 md:mb-0">
    //             {item.dropdownItems ? (
    //               <button
    //                 type="button"
    //                 className="text-white focus:outline-none"
    //                 onClick={() => handleDropdownClick(index)}
    //               >
    //                 {item.label}
    //                 <svg
    //                   className="w-4 h-4 inline-block ml-1"
    //                   fill="none"
    //                   stroke="currentColor"
    //                   viewBox="0 0 24 24"
    //                   xmlns="http://www.w3.org/2000/"
    //                 >
    //                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    //                 </svg>
    //               </button>
    //             ) : (
    //               <a href={item.link} className="text-white">
    //                 {item.label}
    //               </a>
    //             )}
    //             {item.dropdownItems && (
    //               <Dropdown items={item.dropdownItems} isOpen={activeDropdown === index} />
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //       <button
    //         type="button"
    //         onClick={handleMobileMenu}
    //         className="text-white focus:outline-none md:hidden"
    //       >
    //         {isMobileMenuOpen ? 'Close' : 'Menu'}
    //       </button>
    //     </div>
    //     {isMobileMenuOpen && (
    //       <MobileMenu navData={navData} onClose={handleMobileMenu} />
    //     )}
    //   </div>
    // </nav>
  );
};

export default Navbar;
