import React from "react";
import View from "./Components/View";
import image1 from "../../images/Amal tamara/c1 (2).webp";
import image2 from "../../images/Amal tamara/c2 (2).webp";
import image3 from "../../images/Amal tamara/c3 (2).webp";
import image4 from "../../images/Amal tamara/c4 (1).webp";
import image5 from "../../images/Amal tamara/c4 (1).webp";
import Programs from "./Components/Programs";
import astha from "../../images/Amal tamara/astha.webp";
import ektha from "../../images/Amal tamara/ekta.webp";
import idha from "../../images/Amal tamara/idha.webp";
import ProgramContents from "./Components/ProgramContentsLeft";
import tabimage1 from "../../images/Amal tamara/anti ageing.webp"
import tabimage2 from "../../images/Amal tamara/weight management.webp"
import tabimage3 from "../../images/Amal tamara/panchakarma detox.webp"
import tabimage4 from "../../images/Amal tamara/immunity booster.webp"
import tabimage5 from "../../images/Amal tamara/derma.webp"
import tabimage6 from "../../images/Amal tamara/eve care.webp"
import tabimage7 from "../../images/Amal tamara/de stress.webp"
import tabimage8 from "../../images/Amal tamara/ortho care.webp"

const Amaltamara = () => {
  return (
    <div className="main-layout">
      <View
        heading="AMAL TAMARA"
        image1={image1}
        image2={image2}
        image3={image3}
        image4={image4}
        image5={image5}
        videoID="zquHAt2NO6c"
        programs={
          <Programs
            tabs={[
              {
                id: 0, title: 'Anti-Ageing and Rejuvenation', content:
                  <div>
                    <ProgramContents
                      image={tabimage1}
                      heading=""
                      content={
                        <div className="wpb_wrapper text-left">
                          {/* <h4 className="font-bold text-xl mb-4">SHA Essence</h4> */}
                          <p className="mb-4 font-sans text-black">
                            The Anti-Aging and Rejuvenation, or Rasayana, Programme is intended to limit degenerative changes, allowing for graceful aging. Ayurvedic treatments and rasayana oushadha (internal medicines), along with yoga and meditation can help slow the ageing process naturally. This programme aims to revitalise the body’s natural immune systems through detoxifying therapies and stress-management techniques.</p>
                        </div>
                      }
                    >
                    </ProgramContents>
                    <div className="wpb_wrapper text-left">
                      <p className="font-bold mb-2 font-sans text-black">Anti-Aging / Rasayana Programme</p>
                      <p className="mb-4 font-sans text-black">
                        The Anti-Aging or Rasayana Programme is intended to limit degenerative changes, allowing for graceful aging. Ayurvedic treatments and rasayana oushadha (internal medicines), along with yoga and meditation can help slow the ageing process naturally. This programme aims to revitalise the body’s natural immune systems through detoxifying therapies and stress-management techniques. With a special focus on the skin, hair, eyes and toning of Musculo skeletal & nervous system this program includes Ayurvedic body treatments with herbal medicines, therapeutic yoga, and wellness and lifestyle reforming activities.
                      </p>

                      <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black ">
                        <li>Optimises strength of physique and sense organs</li>
                        <li>Keeps the body young and agile</li>
                        <li>Increases physical and mental capabilities</li>
                        <li>Improves firmness and elasticity of skin and muscle</li>
                        <li>Boosts immunity</li>
                        <li>Improves metabolism</li>
                      </ul>
                      <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                      <p className="mb-4 font-sans text-black">7,14, or 21 Days</p>

                      <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Detailed consultation on day of arrival</li>
                        <li>Daily consultations throughout stay</li>
                        <li>Final consultation on departure</li>
                        <li>Five monthly follow-up consultations after departure, conducted online</li>
                        <li>Explanation of cure cuisine – special diet based on body constitution and health status</li>
                      </ul>

                      <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Astha, Ekta, or Idha room, according to booking type</li>
                        <li>Library and Wi-Fi</li>
                        <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                        <li>Complete digital detox, if guests so choose</li>
                        <li>Wellness and lifestyle reforming activities</li>
                      </ul>
                      <p className="font-bold mb-2 font-sans text-black"> Additional Features</p>
                      <p className=" mb-2 font-sans text-black">Daily Ayurvedic treatments as advised by expert Ayurveda physician</p>
                      <p className=" mb-2 font-sans text-black"> One private therapeutic yoga session per week during stay</p>
                      <p className=" mb-2 font-sans text-black">  Daily group yoga and meditation sessions</p>
                      <p className=" mb-2 font-sans text-black">  Specially prepared herbal medication during stay, prepared according by traditional Ayurveda</p>
                      <p className=" mb-2 font-sans text-black">  practitioners with GMP certifications</p>

                    </div>
                  </div>

              },
              {
                id: 1, title: 'Weight Management',
                content: <div>
                  <ProgramContents
                    image={tabimage2}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Ayurveda focusses on holistic solutions that aid the loss of excess weight and improvement of overall health. The Weight Management Programme uses these principles to correct metabolism and balance the endocrine system. The process includes Ayurvedic treatments, administration of oral herbal medication, body treatments, therapeutic yoga, and metabolic corrections through wellness diet and lifestyle reforming activities
                        </p>

                        <p className="mb-4 font-sans text-black">
                          Amal Tamara focusses on scientific solutions and holistic assistive therapies that aid the loss of excess weight and improvement of overall health. The Weight Management Programme uses these principles to correct metabolism and balance the endocrine system.
                        </p>
                        <p className="mb-4 font-sans text-black">
                          The process includes prescribed Ayurvedic treatments, administration of oral herbal medication, therapeutic yoga, and metabolic corrections through wellness diet and lifestyle reforming activities.
                        </p>

                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">


                    <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Reduction of excess body weight</li>
                      <li>Body sculpting</li>
                      <li>Minimised risk of lifestyle disorders and hormonal imbalances</li>
                      <li>Detoxing the body and mind</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                    <p className="mb-4 font-sans text-black">14, 21, or 28 Days</p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Detailed consultation on day of arrival</li>
                      <li>Daily consultations throughout stay</li>
                      <li>Final consultation on departure</li>
                      <li>Five monthly follow-up consultations after departure, conducted online</li>
                      <li>Explanation of cure cuisine – special diet based on body constitution and health status</li>
                      <li>Therapeutic yoga consultation and sessions during stay</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Astha, Ekta, or Idha room, according to booking type</li>
                      <li>Library and Wi-Fi</li>
                      <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                      <li>Complete digital detox, if guests so choose</li>
                      <li>Wellness and lifestyle reforming activities</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                      <li>One private therapeutic yoga session per week during stay</li>
                      <li>Daily group yoga and meditation sessions</li>
                      <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                    </ul>
                  </div>
                </div>

              },
              {
                id: 2, title: 'Panchakarma', content: <div>
                  <ProgramContents
                    image={tabimage3}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          The Panchakarma or Detox Programme is aimed at revitalising the body, mind, and soul, by removing toxins from the body. The programme includes Ayurvedic body treatments with herbal medicines, detox therapies including vamanam, virechanam, vasti, nasyam and therapeutic yoga, Ayurveda diet plans, and wellness and lifestyle reforming activities.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Panchakarma / Detox Programme</p>
                        <p className="mb-4 font-sans text-black">
                          The Panchakarma or Detox Programme is aimed at revitalising the body, mind, and soul, by removing toxins from the body. The programme includes Ayurvedic body treatments with herbal medicines, detox therapies including vamanam, virechanam, vasti, nasyam and rakthamoksham, Ayurveda diet plans, and wellness and lifestyle reforming activities.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">
                    <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Keeps body young and agile</li>
                      <li>Increases physical and mental capabilities</li>
                      <li>Improves skin and muscle tone</li>
                      <li>Boosts immunity</li>
                      <li>Improves metabolism</li>
                      <li>Revives and rejuvenates the body, mind, and soul</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                    <p className="mb-4 font-sans text-black">14 or 21 Days</p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Detailed consultation on day of arrival</li>
                      <li>Daily consultations throughout stay</li>
                      <li>Final consultation on departure</li>
                      <li>Five monthly follow-up consultations after departure, conducted online</li>
                      <li>One special diet orientation and one therapeutic yoga consultation during the stay</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Astha, Ekta, or Idha room, according to booking type</li>
                      <li>Library and Wi-Fi</li>
                      <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                      <li>Complete digital detox, if guests so choose</li>
                      <li>Wellness and lifestyle reforming activities</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                      <li>One private therapeutic yoga session per week during stay</li>
                      <li>Daily group yoga and meditation sessions</li>
                      <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                    </ul>
                  </div>
                </div>
              },
              {
                id: 3, title: 'Immunity Booster ', content: <div>
                  <ProgramContents
                    image={tabimage4}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Immunity Programme aims at enhancing the body’s natural immune response by the correction of digestive mechanisms and the removal of toxins through a detoxification process. This programme includes synchronised Ayurvedic body treatments with the administration of herbal medicines, therapeutic yoga sessions, a curated Ayurvedic diet plan, and wellness and lifestyle reforming activities
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Immunity Booster Programme</p>
                        <p className="mb-4 font-sans text-black">
                          Immunity Programme aims at enhancing the body’s natural immune response by the correction of digestive mechanisms and the removal of toxins through a detoxification process. This programme includes synchronised Ayurvedic body treatments with the administration of herbal medicines, therapeutic yoga sessions, a curated Ayurvedic diet plan, and wellness and lifestyle reforming activities.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">
                    <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Preservation of health and longevity</li>
                      <li>Increased physical and mental capabilities</li>
                      <li>Increased immunity</li>
                      <li>Resistances to diseases</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                    <p className="mb-4 font-sans text-black">7, 14, or 21 Days</p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Detoxification of the system</li>
                      <li>Increase of digestive fire and improvement of metabolism</li>
                      <li>Oral herbal medication for system correction</li>
                      <li>Equalising any imbalanced doshas related to the condition</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Astha, Ekta, or Idha room, according to booking type</li>
                      <li>Library and Wi-Fi</li>
                      <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                      <li>Complete digital detox, if guests so choose</li>
                      <li>Wellness and lifestyle reforming activities</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                      <li>One private therapeutic yoga session per week during stay</li>
                      <li>Daily group yoga and meditation sessions</li>
                      <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                    </ul>
                  </div>
                </div>
              },
              {
                id: 4, title: 'Derma Care', content: <div>
                  <ProgramContents
                    image={tabimage5}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          The Amal Derma Care Programme focuses on skin ailments, including psoriasis, eczema, and dermatitis. Cause correction mechanisms, through internal medicine, external therapies, therapeutic yoga, diet and lifestyle corrections will help control these conditions.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">
                    <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Better controlled and managed stress that can trigger skin conditions</li>
                      <li>Increased physical and mental capability</li>
                      <li>Improved skin quality</li>
                      <li>Enhanced immunity and improved metabolism</li>
                      <li>Improved overall quality of life</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                    <p className="mb-4 font-sans text-black">14 or 21 Days</p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Detoxification of the system</li>
                      <li>Increase of digestive fire and improvement of metabolism</li>
                      <li>Oral herbal medication for system correction</li>
                      <li>Equalising any imbalanced doshas related to the condition</li>
                      <li>Special diet modifications</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Astha, Ekta, or Idha room, according to booking type</li>
                      <li>Library and Wi-Fi</li>
                      <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                      <li>Complete digital detox, if guests so choose</li>
                      <li>Wellness and lifestyle reforming activities</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                      <li>One private therapeutic yoga session per week during stay</li>
                      <li>Daily group yoga and meditation sessions</li>
                      <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                    </ul>
                  </div>
                </div>
              },
              {
                id: 5, title: 'Eve Care', content: <div>
                  <ProgramContents
                    image={tabimage6}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          The Eve Care Programme helps revitalise the senses, detoxify the body, and restore good health and youthfulness. It can even increase resistance and reduce risk of diseases. Designed especially for women, this programme focusses on common gynaecological ailments like PCOS, menopausal syndrome, age-related degenerative disorders. This programme includes synchronised Ayurvedic body treatments with the administration of herbal medicines, therapeutic yoga sessions, a curated Ayurvedic diet plan, and wellness and lifestyle reforming activities
                        </p>

                        <p className="mb-4 font-sans text-black">
                          The Eve Care Programme helps revitalise hormonal imbalances caused because of stress, incorrect lifestyle, use of contraceptives, or chemicals along with poor nutrition. Ayurveda gives great importance to various physiological conditions a woman goes through in her life.This starts from the care to be taken for a woman from the time she attains menarche to various pathological conditions that women often encounter in her life like menstrual disorders, infertility, polycystic ovarian disease, endometriosis, uterine fibroids as well as health conditions related to pre, peri and post-menopausal syndrome. All these conditions are taken care of by effective treatments.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">
                    <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Rejuvenation, keeping the body youthful and agile</li>
                      <li>Improves the skin, bone health</li>
                      <li>Restores hormonal balance</li>
                      <li>Provides relief from various gynaecological diseases</li>
                      <li>Increased immunity and resistance to disease</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                    <p className="mb-4 font-sans text-black">7, 14, or 21 Days</p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Detoxification of the system</li>
                      <li>Increase of digestive fire and improvement of metabolism</li>
                      <li>Oral herbal medication for system correction</li>
                      <li>Equalising any imbalanced doshas related to the condition</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Astha, Ekta, or Idha room, according to booking type</li>
                      <li>Library and Wi-Fi</li>
                      <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                      <li>Complete digital detox, if guests so choose</li>
                      <li>Wellness and lifestyle reforming activities</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                      <li>One private therapeutic yoga session per week during stay</li>
                      <li>Daily group yoga and meditation sessions</li>
                      <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                    </ul>
                  </div>
                </div>
              },
              {
                id: 6, title: 'De-stress', content: <div>
                  <ProgramContents
                    image={tabimage7}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Though often ignored, mental health is a significant part of overall wellness. Given the many factors that induce stress in fast-paced urban city life, the Amal Tamara De-Stress Programme is designed to provide a calming experience to reduce stress. Through a combination of therapeutic Ayurvedic treatments, yoga, meditation, and lifestyle-reforming activities, an integrated approach to mental health promises to restore health and tranquillity, both physically and emotionally.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">
                    <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Improves memory, intelligence, sleep, youthfulness, physical endurance, and sense organs</li>
                      <li>Helps in controlling and managing psychological wellbeing</li>
                      <li>Increases physical and mental capability</li>
                      <li>Boosts immunity</li>
                      <li>Improves quality of life</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                    <p className="mb-4 font-sans text-black">7 or 14 Days</p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Detoxification of the system</li>
                      <li>Increase of digestive fire and improvement of metabolism</li>
                      <li>Oral herbal medication for system correction</li>
                      <li>Equalising any imbalanced doshas related to the condition</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Astha, Ekta, or Idha room, according to booking type</li>
                      <li>Library and Wi-Fi</li>
                      <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                      <li>Complete digital detox, if guests so choose</li>
                      <li>Wellness and lifestyle reforming activities</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                      <li>One private therapeutic yoga session per week during stay</li>
                      <li>Daily group yoga and meditation sessions</li>
                      <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Programme Plan</p>
                    <p className="mb-4 font-sans text-black">
                      Programmes will be curated and offered based on an expert Ayurvedic physician’s assessment of the guest and clinical conditions. Specifics may vary from case to case.
                    </p>
                  </div>
                </div>
              },
              {
                id: 7, title: 'Ortho Care', content:
                  <div>
                    <ProgramContents
                      image={tabimage8}
                      heading=""
                      content={
                        <div className="wpb_wrapper text-left">
                          <p className="mb-4 font-sans text-black">
                            The Ortho Care Programme looks into effective treatments for a whole range of orthopedic conditions, including arthritis, sciatica pain, inter-vertebral disc prolapse, joint and muscle ailments, rheumatoid arthritis, osteo arthritis, psoriatic arthritis, gout, spondylosis, and IVDP. This programme includes synchronised Ayurvedic body treatments with the administration of herbal medicines, therapeutic yoga sessions, a curated Ayurvedic diet plan, and wellness and lifestyle reforming activities.
                          </p>
                        </div>
                      }
                    />
                    <div className="wpb_wrapper text-left">
                      <p className="font-bold mb-2 font-sans text-black">Benefits of the Programme</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Improved physical endurance</li>
                        <li>Aid in restoring health of musculo-skeletal system</li>
                        <li>Improved flexibility</li>
                        <li>Improved overall health and stability</li>
                      </ul>

                      <p className="font-bold mb-2 font-sans text-black">Duration of the Programme</p>
                      <p className="mb-4 font-sans text-black">7, 14, or 21 Days</p>

                      <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Detailed consultation on day of arrival</li>
                        <li>Daily consultations throughout stay</li>
                        <li>Final consultation on departure</li>
                        <li>Five monthly follow up consultations after departure, conducted online</li>
                        <li>Explanation of cure cuisine – special diet based on body constitution and health status</li>
                      </ul>

                      <p className="font-bold mb-2 font-sans text-black">Accommodation & Facilities</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Astha, Ekta, or Idha room, according to booking type</li>
                        <li>Library and Wi-Fi</li>
                        <li>Wholesome, organic, vegetarian meals, curated according to body constitution and ailments</li>
                        <li>Complete digital detox, if guests so choose</li>
                        <li>Wellness and lifestyle reforming activities</li>
                      </ul>

                      <p className="font-bold mb-2 font-sans text-black">Additional Features</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Daily Ayurvedic treatments as advised by expert Ayurveda physician</li>
                        <li>One private therapeutic yoga session per week during stay</li>
                        <li>Daily group yoga and meditation sessions</li>
                        <li>Specially prepared herbal medication during stay, prepared by traditional Ayurveda practitioners with GMP certifications</li>
                      </ul>
                    </div>
                  </div>
              },

            ]}
          />
        }

        HotelCardsData={
          [
            {
              id: 1,
              heading: 'ASTHA ROOMS',
              image: astha,
              subHead: " ",
              content: "Complete with a private balcony, overlooking the breath taking vembanadu lake, the Astha rooms at Amal Tamara are perfect for your healing journey. Elegantly designed with elegant hardwood furniture, a convenient workspace to do daily journaling, single or twin beds with linen bedding, a foyer, and a private bathroom, these rooms offer exactly the haven you will need to rest your head each night during your transformative journey.350 sq.ft. + a private balcony overlooking the backwaters."
            },
            {
              id: 2,
              heading: 'EKTA ROOMS',
              image: ektha,
              subHead: "",
              content: "The Ekta rooms offer our guests all the features of an Astha room, with an additional seating area – perfect for those staying with us for longer periods of time, or those who enjoy a little added space. Private bathrooms and balconies allow for all the comforts of home, with the added perk of waking up to the stunning view of the lake outside your window. 450 sq. ft. + a private balcony overlooking the backwaters."
            },
            {
              id: 3,
              heading: 'IDHA ROOMS',
              image: idha,
              subHead: "",
              content: "Our highest category of room, the Idha rooms are the most spacious of those available at Amal Tamara. Enjoy all the features of an Ekta room, with an additional walk-in closet or changing area, designed in the traditional Kerala style of architecture. For a long stay, the added space and comfort can make the world of a difference, offering you the warmth, privacy, and convenience you need to get the most out of your healing journey. 550 sq. ft. + a private balcony overlooking the backwaters."
            },

          ]}
      >
        <span>
          <div>
            The Ayurvedic school of medicine promotes the idea that wellness is not a single set of principles that apply to everyone.
            To treat each individual effectively, an Ayurvedic practitioner will consider several unique factors, including season, geography, state of mind, and body constitution.
          </div>

          <div>
            The three doshas – Vata, Pitta, and Kapha – are described as the biological energies of the human body and mind, providing vital information for the development of a course of treatment. The key to achieving optimal health is the balance of these bio-regulatory principles.
            To put it simply: Every human being is different. And as a result, will require a unique set of assessments and treatments to offer them a completely balanced and healthy life. At Amal Tamara, we promise our guests completely personalised treatment programmes, that serve their needs, their concerns, and their goals.</div>

        </span>
      </View >
    </div >
  );

}
export default Amaltamara;
