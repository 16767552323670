import React, { useState } from "react";

const HotelCardList = ({ HotelCardsData }) => {
  const [expandedCardId, setExpandedCardId] = useState(null); // state for expanded card

  const toggleExpand = (cardId) => {
    if (expandedCardId === cardId) {
      setExpandedCardId(null); // collapse the expanded card if clicked again
    } else {
      setExpandedCardId(cardId); // expand the clicked card
    }
  };

  const truncatedContent = (content, maxLength) => {
    if (!content || content.length <= maxLength) return content;
    return content.toString().substring(0, maxLength) + "...";
  };

  return (
    <div className="flex flex-wrap justify-center mt-2 pb-5 " >
      {HotelCardsData?.map((card) => (
        <div
          key={card.id}
          className="rounded  border  overflow-hidden shadow-lg p-5 mt-2"
        >
          <div className=" grid lg:grid-cols-3  md:grid-cols-3  sm:grid-cols-1">
            <div style={{ width: "100%" }} className="flex">
              <img
                // className=" md:max-w-md sm:w-full "
                style={{ width: "100%"  }}
                src={card?.image}
                alt="Card"
              />
            </div>
            <div
              className="sm:w-full px-6 md:col-span-2  "
              style={{ position: "relative"}}
            >
              <div className=" font-sans text-xl mb-2 font-bold">{card.heading}</div>
              <p className="text-gray-700  text-base text-justify font-sans">
                {expandedCardId === card.id
                  ? card.content
                  : truncatedContent(card.content, 550)}
              </p>
              {/* {card.modalcontent && ( */}
              <div style={{ position: "absolute", bottom: "0px", right: 25 }}>
                <button
                  onClick={() => toggleExpand(card.id)}
                  className="text-blue-500 hover:underline"
                >
                  {expandedCardId === card.id ? "Show Less" : "Read More"}
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
          <div>{expandedCardId === card.id && card.modalcontent}</div>
        </div>
      ))}
    </div>
  );
};

export default HotelCardList;
