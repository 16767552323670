import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Navigate, Routes, BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomeCarousel from "./components/HomeCarousel";
import FullScreenCarousel from "./components/Carousel";
import IndexRoutes from "./Routes/indexRoutes";
import MainLayout from "./components/MainLayout";

function App() {
  const appRoutes = Object.keys(IndexRoutes).map((key) => {
    const route = IndexRoutes[key];
    if (!route.isSecured) return { ...route };
    return { ...route };
  });

  const wrapperLayout = (child, key) => {
    console.log(child, "child");
    return <MainLayout>{child}</MainLayout>;
  };

console.log(appRoutes,"appRoutes")
  return (
    <div className="App">
      {/* <header
        className={`w-full fixed top-0 z-50 transition duration-300 ease-in-out ${
          isScrolled ? "bg-white shadow-md" : "bg-transparent"
        }`}
      >
        <Navbar />
      </header>
      <FullScreenCarousel/> */}
      <BrowserRouter>
          <Routes>
            {appRoutes &&
              Object.keys(appRoutes).map((key) => {
                const route = appRoutes[key];
                return (
                  <Route
                    key={route.key}
                    path={route.path}
                    exact={route.exact}
                    element={wrapperLayout(<route.component />, route.key)}
                    // component={route.component}
                  />
                );
              })}
                <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
