import React from "react";

const View = ({ BackgroundImage, heading, content, programs, lastContent }) => {
  return (
    <div>
      <div className="header relative">
        <img
          src={BackgroundImage}
          className="background-Medical-spa w-full"
          alt="backround-image"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="sm:text-xl md:text-7xl font-bold font-sans text-white">{heading}</h1>
        </div>
      </div>
      <div className="bg-gray-300 py-8">
        <div className=" mx-auto">{content}</div>
      </div>
      <div className=" mx-auto">{programs}</div>
      <div className="">{lastContent}</div>
    </div>
  );
};

export default View;
