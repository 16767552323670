import React, { Component } from "react";
import View from "./Components/View";
import image1 from "../../images/somatheeram/c1.webp";
import image2 from "../../images/somatheeram/c2.webp";
import image3 from "../../images/somatheeram/c3.webp";
import image4 from "../../images/somatheeram/c2.webp";
import Programs from "./Components/Programs";
import tabimage1 from "../../images/ananda/ananda-tab-img1.jpg";
import tabimage2 from "../../images/ananda/ananda-tab-img2.jpg";
import tabimage3 from "../../images/ananda/ananda-tab-img3.jpg";
import anandspa from "../../images/ananda/anandspa1.jpg";
import anandspa2 from "../../images/ananda/anandspa2.jpg";
import anandspa3 from "../../images/ananda/anandspa3.jpg";
import GuestRoom from "../../images/ananda/guest-room.jpg";
import ProgramContentsLeft from "./Components/ProgramContentsLeft";
import ProgramContentsRight from "./Components/ProgramContentsRight";
import GardenSuite from "../../images/ananda/garden-suite.jpg";
// import HotelDetails from "./Components/HotelDetails";
// import GuestRooms from "./Components/GuestRoom";
import ProgramContents from "./Components/ProgramContentsLeft";
import AnandaSuit from "../../images/ananda/ananda-suite.jpg";
import StandardCottage from "../../images/somatheeram/Standard-Cottage-G1.jpg"
import DeluxeCottage from "../../images/somatheeram/Deluxe-Cottage.jpg"
import KeralaStandard from "../../images/somatheeram/Kerala-Standard-Unit-G1.jpg"
import KeralaDeluxeSuite from "../../images/somatheeram/Kerala-Deluxe-G1.jpg"
import Siddhartha from "../../images/somatheeram/Lay_Sidhartha_Galerie.jpg"
import SpecialCottage from "../../images/somatheeram/Special-Cottage-G10.jpg"
const NavData1 = ["Wellness Programmes", "Spa at Ananda"];
const NavItem = [
    "Guest Room",
    "Garden Suite",
    "Ananda Suite",
    "Viceregel Suite",
    "Ananda Villas"
];

const Somatheeram = () => {

    return (
        <div>
            <View
                heading="Somatheeram"
                // videoID="Pb9CxO9niP0"
                image1={image1}
                image2={image2}
                image3={image3}
                image4={image4}
                programs={
                    <Programs
                        NavData={NavData1}

                        tabs={[

                            {
                                id: 0, title: 'Ayurveda Panchakarma and Rasayana Retreats',
                                content: <div>
                                    <ProgramContentsLeft
                                        image={tabimage1}
                                        heading=""
                                        content={
                                            <div>
                                                <div>
                                                    Experience the ancient healing science of Ayurveda amidst the serene beauty of Kerala, India. At Somatheeram Ayurveda Hospital in a Resort Ambiance, immerse yourself in holistic treatments tailored to rejuvenate your body, mind, and soul. Our expert Ayurvedic doctors prescribe personalized Panchakarma and Rasayana Packages, utilizing natural ingredients for unparalleled therapeutic benefits.
                                                </div>
                                                <h4 className='font-sans text-black'>Each package includes:</h4>
                                                <ul style={{ listStyleType: 'disc' }} className='list-outside ml-4 font-sans text-black'>
                                                    <li className="list-item">Accommodation in a tranquil resort setting </li>
                                                    <li className="list-item">Ayurvedic treatments administered under expert supervision </li>
                                                    <li className="list-item">Full board Ayurvedic vegetarian cuisine</li>
                                                    <li className="list-item">Complimentary daily Yoga classes and backwater boat tours </li>

                                                </ul>

                                                <h4 className='font-sans text-black mt-5'>Choose from a variety of programs:</h4>
                                                <ul style={{ listStyleType: 'disc' }} className='list-outside ml-4 font-sans text-black'>
                                                    <li className="list-item">Rejuvenation Therapy (Rasayana Chikilsa) </li>
                                                    <li className="list-item">Body Purification (Shodhana Chikilsa) </li>
                                                    <li className="list-item">Slimming Programme </li>
                                                    <li className="list-item">Stress Management Programme </li>
                                                    <li className="list-item">Beauty Care Programme </li>
                                                    <li className="list-item">Ayurveda Anti-Ageing Programme </li>
                                                </ul>
                                            </div>
                                        } />
                                </div>
                            },
                            {
                                id: 1, title: 'Prime Ayurvedic Treatments',
                                content: <div>
                                    <ProgramContentsLeft
                                        image={anandspa}
                                        heading=""
                                        content={
                                            <div>
                                                <p>
                                                    Explore a spectrum of authentic Ayurvedic treatments meticulously designed to restore balance and vitality:
                                                </p>
                                                <ul style={{ listStyleType: 'disc' }} className='list-outside ml-4 font-sans text-black'>
                                                    <li className="list-item">Pizhichil</li>
                                                    <li className="list-item">Njavarkizhi</li>
                                                    <li className="list-item">Dhara (Thakra Dhara & Ksheera Dhara, Thyladhara)</li>
                                                    <li className="list-item">Dhashamoola Ksheera Dhara</li>
                                                    <li className="list-item">Sarvanga Kashaya Dhara</li>
                                                    <li className="list-item">Vasthi (Matra Vasthi, Sneha Vasthi, Kashaya Vasthi)</li>
                                                    <li className="list-item">Sirovasthi</li>
                                                    <li className="list-item">Udvarthanam</li>
                                                    <li className="list-item">Abhyangam</li>
                                                    <li className="list-item">Nasyam</li>
                                                    <li className="list-item">Sneha Panam</li>
                                                    <li className="list-item">Lepanam</li>
                                                    <li className="list-item">Dhanyamla Dhara</li>
                                                    <li className="list-item">Yoni Prakshalanam</li>
                                                    <li className="list-item">Kativasthi</li>
                                                    <li className="list-item">Urovasthi</li>
                                                    <li className="list-item">Ksheeradhoomam</li>
                                                    <li className="list-item">Thalam</li>
                                                    <li className="list-item">Kizhi (Part and Full)</li>
                                                    <li className="list-item">Marma Massage</li>
                                                    <li className="list-item">Lekhaneeeyam (Part and Full)</li>
                                                    <li className="list-item">Karnapoornam</li>
                                                    <li className="list-item">Tharpanam</li>
                                                    <li className="list-item">Mukhalepam</li>
                                                    <li className="list-item">Medicated Steam Bath</li>
                                                </ul>

                                            </div>
                                        }
                                    />


                                </div>
                            },
                            {
                                id: 2, title: 'International Institute of Yoga',
                                content: <div>
                                    <ProgramContentsLeft
                                        image={anandspa}
                                        heading=""
                                        content={
                                            <div>
                                                <p>
                                                    Embark on a transformative journey with Yoga, the ancient art of holistic living. Somatheeram Ayurveda Group proudly presents the International Institute of Yoga, offering certified Hatha Yoga Teacher Training Courses accredited by Yoga Alliance International and Bharat Sevak Samaj.</p>
                                                <h4 className='font-sans text-black' >Our comprehensive courses include:</h4>
                                                <ul style={{ listStyleType: 'disc' }} className='list-outside ml-4 font-sans text-black'>
                                                    <li className="list-item">100 hours Basic level Yoga Teacher Training</li>
                                                    <li className="list-item">200 hours Yoga Teacher Training</li>
                                                    <li className="list-item">300 hours Intermediate level Yoga Teacher Training</li>
                                                    <li className="list-item">500 hours Advanced level Yoga Teacher Training</li>

                                                </ul>
                                                <p>
                                                    Under the guidance of Swami Gorakhnath, Director of Studies, delve deep into the philosophy and practice of Yoga, empowering yourself to become a Registered Yoga Teacher RYT.
                                                </p>

                                            </div>
                                        }
                                    />


                                </div>
                            },
                            {
                                id: 3, title: 'Ayurvedic Diet and Lifestyle',
                                content: <div>
                                    <ProgramContentsLeft
                                        image={anandspa}
                                        heading=""
                                        content={
                                            <div>
                                                <p>
                                                    Experience the vitality of Ayurvedic living through nourishing vegetarian cuisine and mindful practices. Discover the wisdom of Ayurveda, advocating for natural purity and balance in diet and lifestyle choices.</p>
                                                <h4 className='font-sans text-black' >Explore the six tastes of Ayurveda:</h4>
                                                <ul style={{ listStyleType: 'disc' }} className='list-outside ml-4 font-sans text-black'>
                                                    <li className="list-item">Sweet</li>
                                                    <li className="list-item">Sour</li>
                                                    <li className="list-item">Salty</li>
                                                    <li className="list-item">Pungent</li>
                                                    <li className="list-item">Bitter</li>
                                                    <li className="list-item">Astringent</li>

                                                </ul>
                                                <p>
                                                    Nurture your digestive fire (Agni) with fresh, wholesome foods, following the ancient traditions of Ayurveda to promote optimal health and well-being.   </p>

                                            </div>
                                        }
                                    />


                                </div>
                            },
                            {
                                id: 4, title: 'Yoga Retreat at Somatheeram',
                                content: <div>
                                    <ProgramContentsLeft
                                        image={anandspa}
                                        heading=""
                                        content={
                                            <div>
                                                <p>
                                                    Embark on a transformative journey at Somatheeram Ayurveda Resort, the world's first Ayurveda and Yoga retreat. Immerse yourself in the essence of Ayurveda and Yoga amidst Kerala's natural beauty.</p>
                                                <h4 className='font-sans text-black' >Experience:</h4>
                                                <ul style={{ listStyleType: 'disc' }} className='list-outside ml-4 font-sans text-black'>
                                                    <li className="list-item">Harmony and peace through holistic healing practices</li>
                                                    <li className="list-item">Authentic Ayurvedic treatments and Yoga sessions</li>
                                                    <li className="list-item">Wholesome preventive healthcare in a serene environment</li>
                                                </ul>
                                                <p>
                                                    Nurture your digestive fire (Agni) with fresh, wholesome foods, following the ancient traditions of Ayurveda to promote optimal health and well-being.   </p>
                                            </div>
                                        }
                                    />


                                </div>
                            }
                        ]}

                    ></Programs>
                }


                HotelCardsData={[
                    {
                        id: 1,
                        heading: 'Siddhartha Deluxe Suite',
                        image: Siddhartha,
                        subHead: " ",
                        content: 'The Siddhartha Deluxe Suite is a unique villa made of granite, featuring an exceptionally spacious living room. The bedroom offers a fantastic view of the sea, while the terrace provides a panoramic view of the coast. The villas standout feature is its open-air bathroom, offering the most luxurious way to enjoy an Ayurvedic treatment.',
                        modalcontent:
                            <div className="flex flex-wrap ">
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">
                                        </div>
                                        <p className="text-gray-700 text-base text-justify ">
                                        </p>  </div>
                                </div>
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        {/* <div className="font-bold text-xl mb-2">Deluxe Valley View Rooms</div> */}
                                        <p className="text-gray-700 text-base text-justify">
                                            Sea view, Air conditioning, Terrace, Outdoor bathroom, En-suite bathroom, Living room, Fan </p>
                                    </div>
                                </div>

                            </div>
                    },

                    {
                        id: 2,
                        heading: 'Kerala Deluxe Suite',
                        image: KeralaDeluxeSuite,
                        subHead: " ",
                        content:
                            "Formerly inhabited by South Indian aristocrats, the Kerala Deluxe Suites are richly decorated wooden houses, some dating back over three centuries. After extensive restoration, they have been rebuilt within the resort. These suites comprise a bedroom and a spacious living room, traditionally furnished yet offering modern comfort. Enjoy a wonderful sea view from your private terrace.",
                        modalcontent:
                            <div className="flex flex-wrap ">
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">
                                        </div>
                                        <p className="text-gray-700 text-base text-justify ">
                                        </p>  </div>
                                </div>
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        {/* <div className="font-bold text-xl mb-2">Deluxe Valley View Rooms</div> */}
                                        <p className="text-gray-700 text-base text-justify">
                                            Sea view, Air conditioning, Terrace, Outdoor bathroom, En-suite bathroom, Living room, Fan </p>
                                    </div>
                                </div>

                            </div>

                    },

                    {
                        id: 3,
                        heading: 'Kerala Standard Unit',
                        image: KeralaStandard,
                        subHead: " ",
                        content: "Guests staying in our Kerala standard houses experience a special atmosphere. Once home to South Indian aristocrats, these historic wooden houses boast a long tradition, with some over three centuries old. Restored extensively and rebuilt within the resort, these houses feature a spacious bedroom with an en-suite bathroom. Equipped with air conditioning and a private terrace, you can enjoy your stay in traditional South Indian style."
                        ,
                        modalcontent:
                            <div className="flex flex-wrap ">
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">
                                        </div>
                                        <p className="text-gray-700 text-base text-justify ">
                                        </p>  </div>
                                </div>
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        {/* <div className="font-bold text-xl mb-2">Deluxe Valley View Rooms</div> */}
                                        <p className="text-gray-700 text-base text-justify">
                                            Sea view, Air conditioning, Terrace, Outdoor bathroom, En-suite bathroom, Living room, Fan </p>
                                    </div>
                                </div>

                            </div>
                    },
                    {
                        id: 4,
                        heading: 'Deluxe Cottage',
                        image: DeluxeCottage,
                        subHead: " ",
                        content: "Situated in preferred locations within the resort, Deluxe Cottages offer a fantastic view of the sea from the bay window or terrace. Elegantly furnished and flooded with light through bay windows, these cottages meet the highest standards with en-suite bathrooms.",
                        modalcontent:
                            <div className="flex flex-wrap ">
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">
                                        </div>
                                        <p className="text-gray-700 text-base text-justify ">
                                        </p>  </div>
                                </div>
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        {/* <div className="font-bold text-xl mb-2">Deluxe Valley View Rooms</div> */}
                                        <p className="text-gray-700 text-base text-justify">
                                            Sea view, Air conditioning, Terrace, Outdoor bathroom, En-suite bathroom, Living room, Fan </p>
                                    </div>
                                </div>

                            </div>
                    },
                    {
                        id: 5,
                        heading: 'Special Cottage',
                        image: SpecialCottage,
                        subHead: " ",
                        content: "The Special Cottages exude a unique charm with their round shape and palm leaf roofs. Enjoy the sea view from your room or terrace, depending on the location. These detached cottages feature a spacious bedroom with an adjoining bathroom, air conditioning, and a ceiling fan." ,
                        modalcontent:
                            <div className="flex flex-wrap ">
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">
                                        </div>
                                        <p className="text-gray-700 text-base text-justify ">
                                        </p>  </div>
                                </div>
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        {/* <div className="font-bold text-xl mb-2">Deluxe Valley View Rooms</div> */}
                                        <p className="text-gray-700 text-base text-justify">
                                            Sea view, Air conditioning, Terrace, Outdoor bathroom, En-suite bathroom, Living room, Fan </p>
                                    </div>
                                </div>

                            </div>
                    },
                    {
                        id: 6,
                        heading: 'Standard Cottage',
                        image: StandardCottage,
                        subHead: " ",
                        content: "Simple yet beautiful, the Standard Cottages are made of natural stone or brick, typical of southern Indian architecture. Each cottage features a bedroom with an adjoining bathroom, ceiling fans, protective mosquito nets, and welcoming bay windows. Located in the upper part of the resort, they are ideal for guests with limited mobility, offering easy access to the Ayurveda clinic and restaurant."  ,
                        modalcontent:
                            <div className="flex flex-wrap ">
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">
                                        </div>
                                        <p className="text-gray-700 text-base text-justify ">
                                        </p>  </div>
                                </div>
                                <div className="max-w-full rounded overflow-hidden shadow-lg m-4" >
                                    <div className="px-6 py-4">
                                        {/* <div className="font-bold text-xl mb-2">Deluxe Valley View Rooms</div> */}
                                        <p className="text-gray-700 text-base text-justify">
                                            Sea view, Air conditioning, Terrace, Outdoor bathroom, En-suite bathroom, Living room, Fan </p>
                                    </div>
                                </div>

                            </div>
                    },

                ]}

            >
                <div>
                    Discover Authentic Ayurveda Experience in Kerala, India
                </div>
            </View>
        </div>
    );
}
export default Somatheeram
