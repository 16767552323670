import React, { useState } from "react";

const Programs = ({ tabData, tabs }) => {


  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // console.log(activeTab.id, "activeTab.id")

  return (
<div className="w-full bg-white text-black p-5 ">
      <div className="w-full text-center text-3xl sm:text-4xl font-bold font-sans mb-6 sm:mb-10 ">Programs</div>
     <div className="border-t border-lightGray my-4"></div>
      <div className="flex flex-wrap items-center justify-center">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`py-2 px-4 mr-2 mb-2 text-sm sm:text-base text-black focus:outline-none font-sans  ${
              activeTab.id === tab.id
                ? 'bg-lightOrange text-white rounded-t-lg'
                : 'bg-lightGray rounded-t-lg'
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="w-full">
        <p className="text-sm sm:text-base font-sans" style={{ color: 'black' }}>
          {activeTab.content}
        </p>
      </div>
    </div>
  );
};


export default Programs;
