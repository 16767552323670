import React, { useState, useEffect } from "react";
import BacgroundImage from "../../../images/sha-wellness.jpg";
import YouTube from "react-youtube";
import FullScreenCarousel from "../../Carousel";
import HotelCardList from "../../HotelCard";

const View = ({
  heading,
  image1,
  image2,
  image3,
  image4,
  videoID,
  children,
  programs,
  Hotels,
  HotelCardsData
}) => {

  const [playerWidth, setPlayerWidth] = useState(0);
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    // Fetch video data or directly set videoId here
    setVideoId(videoID);
  }, [videoID]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      // Calculate the player width based on screen size
      let width = screenWidth;
      if (screenWidth >= 768) {
        // width = document.querySelector(".max-w-screen-lg").clientWidth;
        width = screenWidth * 0.75;

      } else if (screenWidth >= 640) {
        width = screenWidth - 32;
      } else {
        width = screenWidth * 0.75;
      }


      setPlayerWidth(width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const opts = {
    height: Math.ceil((playerWidth * 9) / 16),
    width: playerWidth,
    playerVars: {
      autoplay: 1,
    },
  };

  const endedVideo = (e) => {
    console.log("Video ended");
    // Handle any logic after the video ends
  };

  // console.group("View");
  // console.log({ heading, image1, image2, image3, image4, videoID });
  // console.groupEnd();

  return (
    <div style={{ backgroundColor: 'black' }}   >
      {/* <div>
        <div className="header relative" >
          <img
            src={BacgroundImage}
            className="h-40 object-cover w-full"
            alt="backround-image"
          />
          <div   className="h-40 object-cover w-full bg-white" ></div>
          <div className="container absolute bottom-0 text-center">
            <h1 className=" container text-4xl font-bold">{heading}</h1>
          </div>
        </div>
      </div> */}
      <div className=" mx-auto ">
        <div style={{ backgroundColor: 'white', color: 'black' }}>
          {/* <div className="col-span-1"> */}
          <div className="max-w-full mx-auto">
            <FullScreenCarousel
              image1={image1}
              image2={image2}
              image3={image3}
              image4={image4}
              heading={heading}
            />
            {/* </div> */}
          </div>
          
          <div className=" text-black p-4 text-justify  px-4 sm:px-6 lg:px-8 ">
            {children}
          </div>
        </div>
        <div className=" mt-4  text-white px-4 sm:px-6 lg:px-8 ">
          <div className=" w-full md:w-auto ">{programs}</div>
          <div className=" w-full md:w-auto">{Hotels}</div>
          <div className="flex justify-center bg-black">
            
            <div
              className="max-w-screen-lg w-full"
              style={{ maxWidth: `${playerWidth}px` }}
            >
              {videoId &&
                <YouTube
                  videoId={videoId}
                  opts={opts}
                // onReady={(event) => console.log("Video is ready")}
                // onEnd={endedVideo}
                />}
            </div>
          </div>
          <HotelCardList HotelCardsData={HotelCardsData} />
        </div>
      </div>
    </div>
  );
};

export default View;
