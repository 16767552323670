import React, { useState } from "react";
import sreejith from "../images/sreejith.jpg";
// import Particles from "react-particles-js";

const Specialities = () => {
  const [contactUs, setContactUs] = useState(false);

  const openModal = () => {
    setContactUs(!contactUs);
  };

  const closeModal = () => {
    setContactUs(false);
  };

  return (
    <div>
      <div className="card-border specialities-parent bg-gray-200">
        <div className="card-body">
          <div
            className="specialities-data p-4 md:p-20 lg:p-20 rounded-lg relative "
          >
            <div className="absolute w-full overflow-hidden max-h-screen">
              {/* <Particles
                params={{
                  particles: {
                    size: {
                      value: 4
                    },
                    number: {
                      value: 80,
                      density: {
                        enable: true,
                        value_area: 800
                      }
                    },
                    color: {
                      value: "#a1a1a1"
                    },
                    line_linked: {
                      shadow: {
                        enable: true,
                        color: "#a1a1a1",
                        blur: 1
                      }
                    }
                  }
                }}
              /> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
              <div className="text-center">
                <img
                  src={sreejith}
                  alt="hotel-image"
                  className="w-full md:w-72 mx-auto rounded-full "
                />
              </div>
              <div className="text-center">
                <h1 className="text-2xl font-serif md:text-4xl">Sreejith GOPI</h1>
                <p className="text-sm md:text-base leading-6 text-justify md:leading-6">
                  CEO of LUXURY VOYAGE WELLNESS, Based in France and Kerala,
                  India. Sreejith started his career as a Yoga Therapist and he
                  is one of the Watsu Practitioner. He has a permanent resident
                  permit in France, worked at Private luxury Yacht and different
                  Five-star hotels. He has visited more than 100 countries as a
                  traveller. From that, he got inspired to start travel and
                  tourism and wishes to share his experiences and information on
                  Detox, Weight loss, Anti-age, Adventure, Relaxation, and other
                  activities with guests.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialities;
