import React, { useState ,useEffect} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";


export default function MainLayout({ children }) {
  const [menu, setMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuOpen = () => {
    setMenu(!menu);
  };
  menu && console.log("ok");
  return (
    <div>
         <header
        className={`w-full fixed top-0 z-50 transition duration-300 ease-in-out ${
          isScrolled ? "bg-white shadow-md" : "bg-transparent"
        }`}
      >
        <Navbar />
      </header>
      {children}
      <Footer/>

    </div>
  );
}
