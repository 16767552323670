import React, { Component } from 'react'
import HomeLayout from './KTDC/HomeLayout.jsx'
import Footer from './Footer.js'
const KTDC = () => {

    return (
        <>
        <div className='wrapper p-5'>
            <HomeLayout />
        </div>
        </>
    )
}
export default KTDC
