import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slide1 from "../images/ktdc/slider1.jpg";
import slide2 from "../images/ktdc/slider5.jpg";
import slide3 from "../images/ktdc/slider2.jpg";
import slide4 from "../images/ktdc/slider3.jpg";
import slide5 from "../images/ktdc/slider4.jpg";

const FullScreenCarousel = ({
  image1,
  image2,
  image3,
  image4,
  image5,
  heading,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div className="w-full  relative">
      <Slider {...settings}>
        <div className="w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={image1 ? image1 : slide1}
            alt="slide-1"
          />
        </div>
        <div className="w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={image2 ? image2 : slide2}
            alt="slide-2"
          />
        </div>
        <div className="w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={image3 ? image3 : slide3}
            alt="slide-3"
          />
        </div>
        <div className="w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={image4 ? image4 : slide4}
            alt="slide-4"
          />
        </div>
        {/* <div className="w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={image5 ? image5 : slide5}
            alt="slide-5"
          />
        </div> */}
        {/* Add more slides as needed */}
      </Slider>
      <div className="absolute inset-0 flex items-center justify-center">
        <h1 className="sm:text-xl md:text-7xl font-bold font-sans text-white uppercase">
          {heading}
        </h1>
      </div>
    </div>
  );
};

export default FullScreenCarousel;
