import React from "react";

const ResortLayout = ({ image1, contentTitle, content, children }) => {
  return (
    <div>
      <div className="flex flex-wrap items-center mb-4 lg:mb-5">
        <div className="w-full lg:w-1/2">
          <img
            className="w-full mb-3 lg:mb-0"
            src={image1}
            alt=""
          />
        </div>
        <div className="w-full lg:w-1/2 p-5">
          <div className="text-center lg:text-left">
            <h4>{contentTitle}</h4>
            <p className="text-gray-700 mb-0 text-justify">{content}</p>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResortLayout;
