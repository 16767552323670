import React, { Component } from 'react'
// import ScrollAnimation from 'react-animate-on-scroll';
import flight from "../images/Services/S4_flight.webp";
import taxi from "../images/Services/S1_Taxi.webp";
import travelguide from "../images/Services/s2_travel guide.webp";
import detox from "../images/Services/s5_detox.webp";
import medicalSpa from "../images/Services/s6_medspa.webp";
import Weightloss from "../images/Services/s3_WL.webp";
import sportsinj from "../images/Services/s7_sportsinj.webp";
import antiAge from "../images/Services/s8_antiage.webp";


const ServiceItem = ({ icon, title, link, imageAlt, imageUrl, content }) => {
  return (
    <div className="box wow bounceInUp" data-wow-duration="1.4s">
      <div className="icon text-center">{icon}</div>
      <h4 className="title text-center">
        <a
          // href={link}
          className='font-serif'
        >{title}</a>
      </h4>
      <div className="flight-item text-center">
        <a
        //  href={link}
        >
          <img src={imageUrl} className="flight-img mx-auto" alt={imageAlt} />
        </a>
        <div
          className=' text-sm text-justify font-sans mt-2 '>
          {content}
        </div>
      </div>
    </div>
  );
};

const ServicesSection = ({ services }) => {
  return (
    <div>
      <section id="services" className="py-8">
        <div className="container mx-auto">
          <header className="section-header text-center wow fadeInUp">
            <h3 className="text-3xl font-serif font-bold">Services</h3>
            {/* <p>Through Luxury voyage wellness guest we try to give discount</p> */}
          </header>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
            {services.map((service, index) => (
              <ServiceItem
                key={index}
                icon={<i className={service.icon}></i>}
                title={service.title}
                link={service.link}
                imageAlt={service.imageAlt}
                imageUrl={service.imageUrl}
                content={service.content}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};



const ServicesAnimation = () => {

  const servicesData = [
    {
      icon: 'ion-ios-analytics-outline',
      title: 'Ayurveda Detox Programs',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Ayurveda Detox Programs',
      imageUrl: detox,
      content: 'Embark on refreshing Ayurveda Detox Programs, handcrafted with top wellness retreats. Revitalize your body and mind with ancient healing practices, nestled in peaceful partner havens.'
    }, {
      icon: 'ion-ios-analytics-outline',
      title: 'Ayurveda Weight Loss Program',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Ayurveda Weight Loss Program',
      imageUrl: Weightloss,
      content: 'Join our personalized Ayurveda Weight Loss Program, tailored with renowned resorts and caring experts. We guide you gently toward healthier habits in luxurious, nurturing environments.'
    }, {
      icon: 'ion-ios-analytics-outline',
      title: 'Medical Spa',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Medical Spa',
      imageUrl: medicalSpa,
      content: 'Indulge in pure relaxation with our Medical Spa services, in collaboration with exclusive wellness retreats. Let our skilled professionals pamper you with therapeutic treatments, leaving you feeling renewed and invigorated.'
    },
    {
      icon: 'ion-ios-paper-outline',
      title: 'Anti-Aging',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Anti-Aging',
      imageUrl: antiAge,
      content: 'Embark on a journey to age gracefully with our Anti-Aging programs, designed in collaboration with select resorts and caring skincare specialists. Our customized treatments blend ancient wisdom with modern skincare, revealing your natural radiance.'
    },
    {
      icon: 'ion-ios-paper-outline',
      title: 'Sports Injury',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Sports Injury',
      imageUrl: sportsinj,
      content: 'Prioritize your physical well-being with Sports Injury services, available at leading wellness destinations. Receive expert care and rehabilitation, combining traditional methods with modern techniques for a swift return to your active lifestyle.'
    },
    {
      icon: 'ion-ios-analytics-outline',
      title: 'Flight Booking',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Flight Booking',
      imageUrl: flight,
      content: 'Plan your journeys effortlessly with our Flight Booking services. In partnership with leading airlines, enjoy smooth and stress-free booking experiences, focusing on the joy of your upcoming adventure.'
    },
    {
      icon: 'ion-ios-bookmarks-outline',
      title: 'Taxi Services',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Taxi Services',
      imageUrl: taxi,
      content: 'Experience comfort and reliability with our Taxi Service, provided by trusted transportation partners. From hassle-free airport transfers to scenic local rides, our friendly drivers ensure a warm welcome to your destination.'
    },
    {
      icon: 'ion-ios-paper-outline',
      title: 'Travel Guide',
      link: 'https://api.whatsapp.com/send?phone=33660089702',
      imageAlt: 'Travel Guide',
      imageUrl: travelguide,
      content: 'Immerse yourself in local culture with our expert Travel Guide services. Our seasoned guides craft unique experiences that blend tradition with relaxation, ensuring your journey is rich with unforgettable memories.'
    },


  ];
  return (
    <div className='p-4'>
      <ServicesSection services={servicesData} />
    </div>
  )
}
export default ServicesAnimation;
