import React, { useEffect, useState } from 'react'
import View from './Components/View'
import background from '../../images/vaithri/vaithri.jpg'
import YouTube from "react-youtube";
import ProgramContentsLeft from '../Medicalspa/Components/ProgramContentsLeft'
import ProgramContentsRight from '../Medicalspa/Components/ProgramContentsRight'
import contentImage from '../../images/vaithri/vaithri-2.jpg'
import rainForest from '../../images/vaithri/rain-forest.jpg'
const VaithryResorts = () => {

    const [playerWidth, setPlayerWidth] = useState(0);
    const [videoId, setVideoId] = useState('');

    useEffect(() => {
        setVideoId('fQfLRTJSoPc');
    }, []);
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            // Calculate the player width based on screen size
            let width = screenWidth;
            if (screenWidth >= 768) {
                // width = document.querySelector(".max-w-screen-lg").clientWidth;
                width = screenWidth * 0.75;

            } else if (screenWidth >= 640) {
                width = screenWidth - 32;
            } else {
                width = screenWidth * 0.75;
            }


            setPlayerWidth(width);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const opts = {
        height: Math.ceil((playerWidth * 9) / 16),
        width: playerWidth,
        playerVars: {
            autoplay: 1,
        },
    };
    return (
        <div>
            <View BackgroundImage={background} heading="Vythri Resorts" content={
                <div>
                    <div className="vythri-heading">
                        <h4> A rain forest nature experience resort  in Wayanad – North Kerala</h4>
                    </div>
                    <div className="flex justify-center bg-black">
                        <div
                            className="max-w-screen-lg w-full"
                            style={{ maxWidth: `${playerWidth}px` }}
                        >
                            {videoId &&
                                <YouTube
                                    videoId={videoId}
                                    opts={opts}
                                />
                            }
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="p-4">
                            <ProgramContentsLeft image={contentImage} content={<div>
                                <span>
                                    Located in the Western Ghats in Wayanad
                                    district, Vythiri Resort offers an <strong>Original Rainforest Experience</strong>. The Resort is Surrounded
                                    by Natural Streams and located almost 3.5Kms inside the 150 acres of plantation and
                                    adjoining the rain forests. Rustic Themed Accommodations,<strong>Tree Houses</strong>  and Independent Cottages
                                    including <strong>Pool Villas</strong> are located amidst the jungle offers great opportunity to experience
                                    nature at its best.
                                </span>

                            </div>} />
                            <ProgramContentsRight image={rainForest}
                                content={
                                    <div className="text-left">
                                        <h4 className='font-sans text-black'>Vythiri Resort offers the following experiences:</h4>
                                        <ul style={{ listStyleType: 'disc' }} className='ml-4 font-sans text-black'>
                                            <li className="list-item">
                                                Rain forest
                                            </li>
                                            <li className="list-item">
                                                Birding and Butterfly Watching

                                            </li>
                                            <li className="list-item">
                                                Wellness (Yoga & Meditation, Traditional Kerala Ayurveda and Aroma Spa Options)                    </li>
                                            <li className="list-item">
                                                Plantation</li>
                                            <li className="list-item">
                                                Romantic Experiences for Honeymooners.                     </li>
                                        </ul>
                                    </div>}>

                            </ProgramContentsRight>
                        </div>

                    </div>
                </div>
            } />
        </div>
    )
}
export default VaithryResorts
