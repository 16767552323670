import React, { useState } from "react";
import axios from "axios";
import whatsapp from "../images/whatsapp.png";
import FaceBook from "../images/facebook.png";
import instagram from "../images/instagram.png";

const Modal = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    contact: "",
    feedback: "",
    name: "",
    error: false,
    filled: false,
  });

  const sendMail = (evt) => {
    evt.preventDefault();
    const { email, contact, feedback, name } = formData;

    if (!email || !contact || isNaN(contact) || !feedback) {
      setFormData({ ...formData, error: true });
    } else {
      const data = { email, contact, feedback, name };
      axios.post("/api/translator/submitForm", data);
      alert(
        "Thanks for contacting us, you will get a confirmation notification soon"
      );
      props.modalHandle();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value, filled: true });
  };

  return (
    <div className="  p-12">
      <div id="sendmessage" className="hidden">Your message has been sent. Thank you!</div>
      <div id="errormessage" className="hidden"></div>
      <form action="" method="post" role="form" className="contactForm">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-group md:flex md:col-span-2">
            <input
              onChange={handleChange}
              value={formData.name}
              name="name"
              type="text"
              required
              placeholder="Name"
              className="form-control w-full md:w-1/2 p-2 border border-slate-800 rounded-md mb-2 md:mb-0 md:mr-2"
            />

            <input
              onChange={handleChange}
              value={formData.email}
              name="email"
              type="text"
              required
              placeholder="Email"
              className="form-control w-full md:w-1/2 p-2 border border-slate-800 rounded-md"
            />
          </div>


          <div className="form-group col-span-1 md:col-span-2">
            <input
              onChange={handleChange}
              value={formData.contact}
              name="contact"
              required
              placeholder="Contact Number"
              className="form-control w-full p-2 border border-slate-800 rounded-md"
            />
            <div className="validation"></div>
          </div>
          <div className="form-group col-span-1 md:col-span-2">
            <textarea
              onChange={handleChange}
              value={formData.feedback}
              name="feedback"
              required
              placeholder="Message"
              className="form-control w-full p-2 border border-slate-800 rounded-md"
            ></textarea>
            <div className="validation"></div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            value="Submit"
            className="submit-button bg-green hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={sendMail}
          >
            Send Message
          </button>
        </div>
      </form>
      {/* <div className="grid grid-cols-3 gap-3 mt-5">
        <a href="https://www.facebook.com/Luxury-Voyage-Wellness-101579791281788" className="flex justify-center items-center">
          <img src={FaceBook} alt="FaceBook" className="w-20"></img>
        </a>
        <a href="https://api.whatsapp.com/send?phone=33660089702" className="flex justify-center items-center">
          <img src={whatsapp} alt="Whatsapp" className="w-20"></img>
        </a>
        <a href="https://instagram.com/luxuryvoyagewellness?igshid=1i5wf8619te47" className="flex justify-center items-center">
          <img src={instagram} alt="instagram" className="w-20"></img>
        </a>
      </div> */}

    </div>

  );
};

export default Modal;
