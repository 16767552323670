import React, { Component } from "react";
import View from "./Components/View";
import image1 from "../../images/sha-wellness/c1 (1).webp";
import image2 from "../../images/sha-wellness/c2 (1).webp";
import image3 from "../../images/sha-wellness/c3 (1).webp";
import image4 from "../../images/sha-wellness/c4.webp";
import image5 from "../../images/sha-wellness/c5.webp";
import Programs from "./Components/Programs";
// import HotelDetails from "./Components/HotelDetails";
import deluxeRoom from "../../images/sha-wellness/delux.webp";
import Superior from "../../images/sha-wellness/superior.webp";
import grandsuit from "../../images/sha-wellness/grand.webp";
import presidentialsuite from "../../images/sha-wellness/presidential.webp";
import royalsuite from "../../images/sha-wellness/royal.webp";
import Premier from "../../images/sha-wellness/premier residence.webp";
import gardenresidence from "../../images/sha-wellness/garden residance.webp";
import Penthouse from "../../images/sha-wellness/penthouse.webp";
import ProgramContents from "./Components/ProgramContentsLeft";
import tabimage1 from "../../images/sha-wellness/Detox & Optimal Weight.webp"
import tabimage2 from "../../images/sha-wellness/leaders performance.webp"
import tabimage3 from "../../images/sha-wellness/Well-ageing prevention.webp"
import tabimage4 from "../../images/sha-wellness/rebalance & energice.webp"
import tabimage5 from "../../images/sha-wellness/sexual wellness.webp"
import tabimage6 from "../../images/sha-wellness/gul health.webp"
import tabimage7 from "../../images/sha-wellness/cognitive boost.webp"
import tabimage8 from "../../images/sha-wellness/sleep well.webp"
import tabimage9 from "../../images/sha-wellness/quit smocking.webp"

import "./medicalspa.css";

const NavData1 = [
  "Sha Wellness Programmes",
  "Treatments and Services",
  "Accommodation Rates"
];
const NavItem = [
  "Deluxe Suit (70 m2)",
  "Superior Suit (100 m2)",
  "Grand Suit (120 m2)",
  "Presidential Suit (175 m2)",
  "Royal Suit (320 m2 devided up on two floors)"
];

const Shawellness = () => {
  return (
    <div className="main-layout">
      <View
        heading="SHA WELLNESS"
        image1={image1}
        image2={image2}
        image3={image3}
        image4={image4}
        image5={image5}
        videoID="E9vCw_Y0bRw"
        programs={
          <Programs
            NavData={NavData1}

            tabs={[
              {
                id: 0, title: 'Detox & Optimal Weight', content:
                  <div>
                    <ProgramContents
                      image={tabimage1}
                      heading=""
                      content={
                        <div className="wpb_wrapper text-left">
                          {/* <h4 className="font-bold text-xl mb-4">SHA Essence</h4> */}
                          <p className="mb-4 font-sans text-black">
                            This programme is designed to provide remarkable and concrete results in the process of bodily detoxification and metabolic reactivation. Our focus is centered on achieving a deep purification and revitalizing the metabolism, with special attention to attaining effective and long-lasting results that will enable you to progress towards optimal health.
                          </p>
                          <p className="font-bold mb-2 font-sans text-black">Method</p>
                          <p className="mb-4 font-sans text-black">
                            The Detox & Optimal Weight program employs a holistic approach that combines a tailored diet plan, advanced medical therapies, and physical activities. The goal is to detoxify the body, boost metabolism, and achieve a healthy weight through sustainable lifestyle changes.
                          </p>

                        </div>
                      }
                    >
                    </ProgramContents>
                    <div className="wpb_wrapper text-left">
                      <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                      <p className="mb-4 font-sans text-black">
                        Participants can expect significant weight loss, improved metabolic health, enhanced energy levels, and a deeper understanding of healthy eating and lifestyle habits. The program aims to provide long-term weight management solutions and overall well-being.
                      </p>
                      <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                      <p className="mb-4 font-sans text-black">
                        This program is ideal for individuals looking to lose weight, detoxify their bodies, and adopt healthier lifestyle habits. It suits those experiencing weight-related health issues or simply wanting to enhance their physical fitness and vitality.
                      </p>

                      <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black ">
                        <li>General health examination</li>
                        <li>Advanced Preventive Diagnostic tests (Body Composition Analysis, 3D Body Scanner, etc.) </li>
                        <li>Medical consultations at the beginning and end of the program </li>
                        <li>Revitalising medicine consultations </li>
                      </ul>

                      <p className="font-bold mb-2 font-sans text-black">Healthy Nutrition</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Personalized diet plan</li>
                        <li>Nutritional follow-up consultations </li>
                        <li>Natural therapeutic drinks </li>
                      </ul>
                      <p className="font-bold mb-2 font-sans text-black">Natural Therapies</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black">
                        <li>Colon hydrotherapy sessions</li>
                        <li>Cryotherapy sessions </li>
                        <li>SHA Detox therapy </li>
                        <li>Access to the Hydrotherapy Circuit </li>
                      </ul>
                      <p className="font-bold mb-2 font-sans text-black">Physical Performance</p>
                      <ul className="list-disc list-outside mb-4 font-sans text-black ">
                        <li>Fitness evaluation and sessions with a personal trainer</li>
                        <li>Group training sessions </li>
                      </ul>
                    </div>
                  </div>

              },
              {
                id: 1, title: 'Leaders Performance',
                content: <div>
                  <ProgramContents
                    image={tabimage2}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Being aware of how difficult it is in a leadership position to find the time to take care of oneself, we have designed an intensive 7-day programme combining the most effective treatments and methodologies from both scientific and holistic medicine to achieve optimal physical and mental performance. An executive health programme.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          The Leader's Performance program integrates advanced diagnostics, personalized medical treatments, and stress management techniques. It focuses on optimizing cognitive and physical performance through tailored interventions.
                        </p>

                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants will experience improved cognitive function, enhanced physical stamina, reduced stress levels, and better overall health. The program aims to boost leadership capabilities by enhancing mental and physical performance.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Designed for executives, managers, and leaders who seek to enhance their performance and resilience in demanding roles. It is suitable for those looking to improve their cognitive skills, physical health, and stress management abilities.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>General health examination</li>
                      <li>Comprehensive diagnostics</li>
                      <li>Personalized health plan</li>
                      <li>Regular medical consultations</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Stress Management</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Emotional coaching sessions</li>
                      <li>Mindfulness and meditation sessions</li>
                      <li>Relaxation therapies</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Physical and Cognitive Performance</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Fitness and cognitive training sessions</li>
                      <li>Nutritional plan tailored to performance needs</li>
                      <li>Advanced technological treatments</li>
                    </ul>
                  </div>
                </div>

              },
              {
                id: 2, title: 'Well-ageing & Prevention', content: <div>
                  <ProgramContents
                    image={tabimage3}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          This programme aims to slow down the process of cellular degeneration and to reactivate the health potential of each person. The aim is to provoke a natural stimulation and reconstruction of the different metabolic processes and systems, through prevention and the strategic application of innovative treatments of well-ageing medicine.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          This program combines advanced medical diagnostics, personalized nutritional plans, and preventive therapies to promote healthy ageing. It addresses early signs of ageing and helps prevent age-related diseases.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">
                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants can expect improved vitality, enhanced physical and mental health, and delayed onset of age-related conditions. The program aims to promote longevity and a higher quality of life.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Ideal for individuals seeking to maintain their health as they age, prevent age-related diseases, and enhance their overall well-being. It suits those who want to adopt a proactive approach to healthy ageing.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Well-ageing Biological Profile</li>
                      <li>Oxidative stress test</li>
                      <li>Medical consultations</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Healthy Nutrition</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Consultation with nutrition experts</li>
                      <li>Personalized nutrition plan</li>
                      <li>Nutritional supplements</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Natural Therapies</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Colon hydrotherapy</li>
                      <li>Cryotherapy sessions</li>
                      <li>SHA Detox therapy</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Physical and Cognitive Wellness</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Fitness evaluation and sessions</li>
                      <li>Cognitive domain tests</li>
                      <li>Access to the Hydrotherapy Circuit</li>
                    </ul>
                  </div>
                </div>
              },
              {
                id: 3, title: 'Rebalance & Energise', content: <div>
                  <ProgramContents
                    image={tabimage4}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Rebalance & Energise takes an innovative approach that incorporates clinically supported and patented technologies and treatments to relieve stress, rebalance the body's systems and revitalise its functions at the cellular level, providing maximum recovery and dramatically boosting vital energy.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          The Rebalance & Energise program utilizes a combination of personalized diet plans, natural therapies, and technological treatments. It aims to relieve stress, rebalance the body's systems, and revitalize cellular functions.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants will achieve maximum physical and mental vitality, reduced stress levels, and improved overall balance and energy. The program promotes cellular health and longevity.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Suitable for individuals feeling exhausted and looking to regain energy, reduce stress, and adopt healthy lifestyle habits. It is ideal for those wanting to unwind, relax, and learn new health practices.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>General health examination</li>
                      <li>Advanced diagnostic tests</li>
                      <li>Medical consultations</li>
                      <li>Ozone therapy sessions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Healthy Nutrition</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Personalized diet plan</li>
                      <li>Nutritional follow-up consultations</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Natural Therapies</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Bioenergy assessment</li>
                      <li>Acupuncture sessions</li>
                      <li>Relax & Energy sessions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Wellness and Fitness</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Oriental therapy treatments</li>
                      <li>Hydroenergetic cure detox</li>
                      <li>Cryotherapy sessions</li>
                      <li>Fitness sessions with a personal trainer</li>
                    </ul>
                  </div>
                </div>
              },
              {
                id: 4, title: 'Sexual Well-being Pack', content: <div>
                  <ProgramContents
                    image={tabimage5}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Optimal health goes beyond the mere absence of disease to encompass emotional, physical, social, and also sexual well-being. In general, sexual problems are often hidden due to shame or lack of information, which affects the overall state of health. Therefore, it is crucial to improve self-awareness and sexual education in order to achieve the desired sexual fulfilment.
                        </p>
                        <p className="mb-4 font-sans text-black">
                          The SHA Sexual Well-being pack offers a comprehensive approach with various specialists, personalised consultations, and innovative treatments, prioritising both female and male sexual happiness, with the aim of guiding towards maximum well-being.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          This program offers a comprehensive approach to sexual health through personalized consultations, hormonal analyses, and innovative treatments. It focuses on both female and male sexual well-being.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants can expect improved sexual health, enhanced vitality, and a better understanding of their sexual well-being. The program aims to guide individuals towards maximum sexual fulfillment and overall well-being.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Ideal for individuals experiencing sexual health issues or those wanting to enhance their sexual well-being. It suits both men and women looking for personalized and comprehensive sexual health support.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Consultations</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Sexual well-being consultation</li>
                      <li>Hormonal analysis</li>
                      <li>Consultation with a hormone expert</li>
                      <li>Urology/Gynaecology consultation</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Treatments</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Virile Rejuvenation with shock waves (for men)</li>
                      <li>Emsella sessions</li>
                      <li>Vitality Support serum therapy sessions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Cost</p>
                    <p className="mb-4 font-sans text-black">Sexual Well-being Pack (Woman/Man): 2,000€</p>
                  </div>
                </div>
              },
              {
                id: 5, title: 'Gut Health Pack', content: <div>
                  <ProgramContents
                    image={tabimage6}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          The aim of this pack is to restore the balance and proper functioning of the digestive system as much as possible, improving the state of the gut microbiota and the immune, neuroendocrine, and emotional system through an integrative approach. This is done thanks to a detailed assessment of the individual’s current state, in addition to specialised treatments to detoxify and repair the intestinal mucosa to fully purify the organism and improve the tolerance to certain foods, thus optimising the overall health and quality of life.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          The Gut Health Pack utilizes a detailed assessment and specialized treatments to restore the balance and proper functioning of the digestive system. It aims to detoxify and repair the intestinal mucosa.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants can expect improved gut health, enhanced immune function, and better tolerance to certain foods. The program promotes overall health and quality of life by addressing digestive issues comprehensively.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Suitable for individuals experiencing digestive problems, gut dysbiosis, or those wanting to improve their overall gut health. It is ideal for those seeking an integrative approach to digestive wellness.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Medical consultations</li>
                      <li>Intestinal dysbiosis test</li>
                      <li>Intestinal ozone therapy sessions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Therapies</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Visceral osteopathy sessions</li>
                      <li>Emotional eating sessions</li>
                      <li>L-glutamine dietary supplements</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Cost</p>
                    <p className="mb-4 font-sans text-black">Gut Health Pack: 1,450€</p>
                  </div>
                </div>
              },
              {
                id: 6, title: 'Cognitive Boost Pack', content: <div>
                  <ProgramContents
                    image={tabimage7}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          The Cognitive Boost pack combines up-to-date scientific knowledge with advanced technology to assess and develop mental potential. It is designed for people with mild cognitive impairment (MCI) or even for those who, despite normal health conditions, are in search of substantial cognitive improvement. This pack offers detailed assessments and exercises aimed at preventing neurodegenerative diseases and improving cognitive reserve.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          The Cognitive Boost Pack combines scientific knowledge with advanced technology to assess and develop mental potential. It includes detailed assessments and exercises aimed at preventing neurodegenerative diseases.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants will experience improved cognitive function, enhanced memory, and better mental clarity. The program aims to boost cognitive reserve and prevent cognitive decline.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Ideal for individuals with mild cognitive impairment (MCI) or those seeking significant cognitive improvement. It suits people in search of enhanced mental performance and cognitive health.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Assessments and Treatments</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Neurocognitive assessment consultations</li>
                      <li>Neurofeedback sessions</li>
                      <li>Neurotechnical sessions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Therapies</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Mindfulness sessions</li>
                      <li>Neuro training app</li>
                      <li>Dietary supplements (Stress-b Plus, Omega 3 Krill)</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Cost</p>
                    <p className="mb-4 font-sans text-black">Cognitive Boost Pack: 1,350€</p>
                  </div>
                </div>
              },
              {
                id: 7, title: 'Sleep Well', content: <div>
                  <ProgramContents
                    image={tabimage8}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          From an integrative approach, the aim is to identify the causes that generate sleep problems, through measurements with the use of specialised technology to determine important physiological values related to the natural rhythms of the human body. Once the origin of the pathology has been determined, specific treatments and therapies focused on the gradual restoration of the natural sleep cycle are applied, in order to achieve restful sleep and recover adequate energy levels for a full and healthy life.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          The Sleep Well program identifies the causes of sleep problems using specialized technology to determine physiological values. It applies specific treatments to restore the natural sleep cycle.
                        </p>
                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants can expect improved sleep quality, restored energy levels, and better overall health. The program aims to achieve restful sleep and recover adequate energy for a full and healthy life.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Suitable for individuals experiencing sleep disorders or those wanting to improve their sleep quality. It is ideal for anyone looking to restore their natural sleep cycle and enhance overall well-being.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Medical consultations</li>
                      <li>Polygraph for sleep diagnosis</li>
                      <li>Neurotechnical sleep sessions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Therapies</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Acupuncture sessions</li>
                      <li>Mindfulness sessions</li>
                      <li>SHA Sleep & Relax dietary supplements</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Cost</p>
                    <p className="mb-4 font-sans text-black">Sleep Well Pack: 1,650€</p></div>
                </div>
              },
              {
                id: 8, title: 'Quit Smoking', content: <div>
                  <ProgramContents
                    image={tabimage9}
                    heading=""
                    content={
                      <div className="wpb_wrapper text-left">
                        <p className="mb-4 font-sans text-black">
                          Thanks to strategies adapted to the physical and psychological conditions of the patient, rigorous medical follow-up and the application of medical treatments and natural therapies focused on addictions, this pack seeks to help guests stop depending on tobacco in a natural, healthy, and controlled way. The goal is to recover the proper functioning of your body and avoid side effects of withdrawal and prevent relapse. Staff also teach healthy habits that will strengthen guests’ commitment to quitting tobacco permanently, and strengthen their motivation for a wider health recovery.
                        </p>

                        <p className="font-bold mb-2 font-sans text-black">Method</p>
                        <p className="mb-4 font-sans text-black">
                          The Quit Smoking program combines medical treatments and natural therapies focused on addictions. It aims to help participants stop depending on tobacco in a natural, healthy, and controlled way.
                        </p>

                      </div>
                    }
                  />
                  <div className="wpb_wrapper text-left">

                    <p className="font-bold mb-2 font-sans text-black">Expected Outcome</p>
                    <p className="mb-4 font-sans text-black">
                      Participants can expect to overcome nicotine dependence, improve lung health, and adopt healthier habits. The program aims to prevent relapse and support long-term smoking cessation.
                    </p>
                    <p className="font-bold mb-2 font-sans text-black">Target Audience</p>
                    <p className="mb-4 font-sans text-black">
                      Ideal for individuals looking to quit smoking and recover their body's proper functioning. It suits those seeking comprehensive support to overcome tobacco addiction and maintain a smoke-free lifestyle.
                    </p>

                    <p className="font-bold mb-2 font-sans text-black">Medical Services</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Medical consultations</li>
                      <li>Spirometry test</li>
                      <li>Cotinine measurements</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Therapies</p>
                    <ul className="list-disc list-outside mb-4 font-sans text-black">
                      <li>Lung detox nebulisation sessions</li>
                      <li>Acupuncture sessions</li>
                      <li>Sessions with a trained coach in addictions</li>
                    </ul>

                    <p className="font-bold mb-2 font-sans text-black">Cost</p>
                    <p className="mb-4 font-sans text-black">Quit Smoking Pack: 1,250€</p>
                  </div>
                </div>
              },

            ]}
          />
        }

        HotelCardsData={
          [
            {
              id: 1,
              heading: 'Deluxe Suit (70 m2)',
              image: deluxeRoom,
              subHead: "  A deluxe room in a suite with a superb environment",
              content: 'The Deluxe Room offers a spacious 70 m² area with a combined living room and bedroom, a queen-size bed, and a bathroom with a shower. It features a large terrace, a walk-in closet, a safety box, a smart TV, and free WiFi. Guests can enjoy mountain or sea views, and optional amenities include a Jacuzzi on the terrace, healthy snacks, and a digital press service.'
            },
            {
              id: 2,
              heading: 'Superior Suit (100 m2)',
              image: Superior,
              subHead: " One of the most exclusive rooms of SHA",
              content: "The Superior Suite offers 100 m² of space, featuring an independent bedroom with a queen-size bed and an en-suite bathroom with a bathtub. It also includes a lounge-dining room, a guest bathroom with a shower, a walk-in closet, a safety box, a smart TV, free WiFi, and a large terrace with options for mountain or sea views. Additional amenities include a digital press service and a list of healthy snacks."
            },
            {
              id: 3,
              heading: 'Grand Suit (120 m2)',
              image: grandsuit,
              subHead: "  Enjoy all the luxurious services and features",
              content: "The Grand Suite provides 120 m² of luxury, including a master bedroom with a king-size bed, an en-suite bathroom with a bath and shower, and a walk-in closet. The suite also includes a lounge-dining room, an additional space for a home office or extra bedroom, and a large terrace with a Jacuzzi. Guests can enjoy a guest bathroom with a shower, a safety box, a smart TV, free WiFi, and a list of mattress toppers and pillows."
            },
            {
              id: 4,
              heading: 'Presidential Suit (175 m2)',
              image: presidentialsuite,
              subHead: " There are many details that make the presidential suite of the medical hotel very special and complete",
              content: "The Presidential Suite spans 175 m², offering two bedrooms, a lounge-dining room, and a large terrace with a Jacuzzi and sea views. The master bedroom includes a king-size bed and an en-suite bathroom with a bathtub and shower, while the guest bedroom features a queen-size bed and a guest bathroom with a shower. The suite also includes a personal assistant service for 8 hours per day, a safety box, a smart TV, free WiFi, and a list of mattress toppers and pillows."
            },
            {
              id: 5,
              heading: 'Royal Suit (320 m2 devided up on two floors)',
              image: royalsuite,
              subHead: " It has the very latest technology and the services available are intended to guarantee the most satisfactory stay possible",
              content: "The Royal Suite is an expansive 320 m² space with two bedrooms, a living room with a terrace offering 180° views, a kitchen, and a rooftop with 360° views and a private swimming pool. The suite includes a master bedroom with a walk-in closet and an en-suite bathroom with a hydro massage bathtub and shower, a guest bedroom, and a guest bathroom with a queen-size bed. Additional amenities include a personal assistant service for 8 hours per day, complimentary VIP transfer from Valencia and Alicante airports, a safety box, a smart TV, free WiFi, and a list of mattress toppers and pillows."
            }, {
              id: 6,
              heading: 'Garden Residence',
              image: gardenresidence,
              subHead: "",
              content: "The Garden Residence covers 300 m² with two or three bedrooms, a lounge-dining room, a show cooking station, and a large terrace. It includes a private pool, high-tech domotics, a security system, a safety box, a smart TV, free WiFi, and options for additional spaces such as an extra room, a private home cinema, or a wellness room with a massage cabin and sauna. Guests can also enjoy in-residence services, healthy snacks, private parking, and a garden."
            }, {
              id: 7,
              heading: 'Premier Residence',
              image: Premier,
              subHead: "",
              content: "The Premier Residence offers 300 m² of space with two bedrooms, a lounge-dining room, a show cooking station, and a large terrace with mountain views. It includes a private pool, high-tech domotics, a security system, a safety box, a smart TV, free WiFi, and options for additional amenities such as a barbecue and private garden. Guests can also enjoy in-residence services, healthy snacks, and private parking." }, 
              {
              id: 8,
              heading: 'Penthouse Residence',
              image: Penthouse,
              subHead: "",
              content:"The Penthouse Residence is a luxurious 500 m² space with two or three bedrooms, a lounge-dining room, a large terrace, and a rooftop with 360° views. It includes a show cooking station, a leisure and wellness area, and a personal assistant service for 8 hours per day. The master bedroom features a king-size bed and an en-suite bathroom with a bathtub and shower. Additional amenities include a safety box, a smart TV, free WiFi, a list of mattress toppers and pillows, and in-residence services." }
          ]}
      >
        <span>
          <div>
          </div>

        </span>
      </View >
    </div >
  );

}
export default Shawellness;
