import React, { Component } from 'react'
import treatment1 from "../images/gallery2/treatment1.webp";
import treatment5 from "../images/gallery2/treatment5.webp";
import treatment3 from "../images/gallery2/treatment3.webp";
import treatment4 from "../images/gallery2/treatment4.webp";
import aqua from "../images/gallery2/aqua.webp";
import treatment2 from "../images/gallery2/treatment2.webp";
import ayurveda from "../images/gallery2/ayurveda.webp";
import anandspa1 from "../images/gallery2/anandspa1.webp";
import anandspa3 from "../images/gallery2/anandspa3.webp";
const Portfolio = () => {


  const PortfolioItem = ({ imageSrc, category, delay }) => {
    return (
      <div className={`portfolio-item filter-${category} wow fadeInUp`} data-wow-delay={delay}>
        <div className="flex justify-between items-center ">
          <figure>
          <img src={imageSrc} className="w-full h-auto" alt="" />
          </figure>
        </div>
      </div>
    );
  };


  const portfolioData = [
    { imageSrc: aqua, category: 'app', delay: '0s' },
    { imageSrc: treatment1, category: 'app', delay: '0s' },
    { imageSrc: treatment5, category: 'app', delay: '0s' },
    { imageSrc: treatment3, category: 'app', delay: '0s' },
    { imageSrc: treatment2, category: 'app', delay: '0s' },
    { imageSrc: ayurveda, category: 'app', delay: '0s' },
    { imageSrc: anandspa1, category: 'app', delay: '0s' },
    { imageSrc: treatment4, category: 'app', delay: '0s' },
    { imageSrc: anandspa3, category: 'app', delay: '0s' },
    // Add other items similarly with respective image sources, categories, and delays
  ];

  return (


    //     <div className="row portfolio-container">
    //       <div className="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp">
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={aqua} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/app1.jpg"
    //               data-lightbox="portfolio"
    //               data-title="App 1"
    //               className="link-preview"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>
    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>
    //       <div
    //         className="col-lg-4 col-md-6 portfolio-item filter-web wow fadeInUp"
    //         data-wow-delay="0.1s"
    //       >
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={treatment1} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/web3.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="Web 3"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>
    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>
    //       <div
    //         className="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp"
    //         data-wow-delay="0.2s"
    //       >
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={treatment5} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/app2.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="App 2"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>
    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>
    //       <div className="col-lg-4 col-md-6 portfolio-item filter-card wow fadeInUp">
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={treatment3} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/card2.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="Card 2"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>

    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>
    //       <div
    //         className="col-lg-4 col-md-6 portfolio-item filter-web wow fadeInUp"
    //         data-wow-delay="0.1s"
    //       >
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={treatment2} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/web2.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="Web 2"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>

    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>

    //       <div
    //         className="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp"
    //         data-wow-delay="0.2s"
    //       >
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={ayurveda} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/app3.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="App 3"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>

    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>

    //       <div className="col-lg-4 col-md-6 portfolio-item filter-card wow fadeInUp">
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={anandspa1} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/card1.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="Card 1"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>

    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>

    //       <div
    //         className="col-lg-4 col-md-6 portfolio-item filter-card wow fadeInUp"
    //         data-wow-delay="0.1s"
    //       >
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={treatment4} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/card3.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="Card 3"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>

    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>

    //       <div
    //         className="col-lg-4 col-md-6 portfolio-item filter-web wow fadeInUp"
    //         data-wow-delay="0.2s"
    //       >
    //         <div className="portfolio-wrap">
    //           <figure>
    //             <img src={anandspa3} className="img-fluid" alt="" />
    //             <a
    //               href="img/portfolio/web1.jpg"
    //               className="link-preview"
    //               data-lightbox="portfolio"
    //               data-title="Web 1"
    //               title="Preview"
    //             >
    //               <i className="ion ion-eye"></i>
    //             </a>
    //             <a href="#" className="link-details" title="More Details">
    //               <i className="ion ion-android-open"></i>
    //             </a>
    //           </figure>

    //           <div className="portfolio-info"></div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section> 
    //   </div>

    //         <section id="portfolio" className="py-8">
    // <div className="container mx-auto">

    //   <header className="section-header text-center wow fadeInUp">
    //     <h3 className="text-3xl font-bold">GALLERY</h3>
    //   </header>

    //   <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
    //     <div className="portfolio-item filter-app wow fadeInUp" data-wow-delay="0s">
    //       <img src={aqua} className="img-fluid" alt="" />
    //       <div className="flex justify-between items-center mt-2">
    //         <a
    //           href="img/portfolio/app1.jpg"
    //           data-lightbox="portfolio"
    //           data-title="App 1"
    //           className="link-preview"
    //           title="Preview"
    //         >
    //           <i className="ion ion-eye"></i>
    //         </a>
    //         <a href="#" className="link-details" title="More Details">
    //           <i className="ion ion-android-open"></i>
    //         </a>
    //       </div>
    //     </div>
    //     </div>
    //     </div></section>


    <section id="portfolio" className="py-8">
      <div className="container mx-auto">
        <header className="section-header text-center wow fadeInUp">
          <h3 className="text-3xl font-bold font-serif" >GALLERY</h3>
        </header>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mt-8">

          {portfolioData.map((item, index) => (
            <PortfolioItem
              key={index}
              imageSrc={item.imageSrc}
              category={item.category}
              delay={item.delay}
            />
          ))}
        </div>
      </div>
    </section>

  )

}

export default Portfolio;
