import React from "react";

const ProgramContents = ({ image, heading, content }) => {
  return (
    <div className="flex flex-wrap  py-8">
      <div className="w-full md:w-1/2 order-2 md:order-1">
        <img
          src={image}
          alt="hotel-image"
          className="w-full h-auto object-cover rounded-lg shadow-md"
        />
      </div>
      <div className="w-full md:w-1/2 md:pr-5 sm:p-0">
        <div className="text-xl  mb-4 font-sans font-bold">{heading}</div>
        <p className="text-base leading-relaxed text-justify font-sans">{content}</p>
      </div>
    </div>
  );
};

export default ProgramContents;
