import React, { Component } from 'react'
import Modal from './Modal'
import franceIcon from '../images/france.png'
import IndiaIcon from '../images/india.png'
import EmailIcon from '../images/email.png'

export default function HomeScreenFooter() {

  return (
    <div>
      <section id="contact" className="section-bg wow fadeInUp">
        <div className="">
          <div className="section-header">
            <h3>Contact Us</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
            {/* <div className="contact-address">
          <i className="ion-ios-location-outline"></i>
          <h3>GSTIN</h3>
          <address>32CZHPS3490N1ZR</address>
        </div> */}

            <div className="contact-phone">
              <i className="ion-ios-telephone-outline"></i>
              <div className='flex justify-center'>
                <img src={franceIcon} alt='franceIcon' className='w-10 h-10' />
              </div>
              <div className='flex justify-center'>
                <div className='p-2'>
                  <div>France</div>
                  <div
                  // href="tel:+33660089702"
                  >+33 660 089 702</div>
                </div>


              </div>
            </div>

            <div className="contact-phone">
              <i className="ion-ios-telephone-outline"></i>
              <div className='flex justify-center'>
                <img src={IndiaIcon} alt='IndiaIcon' className='w-10 h-10' />
              </div>
              <div className='flex justify-center'>

                <div className='p-2'>
                  <div>India</div>
                  <div
                  //  href="tel:+918086080440"
                  >+91 808 608 0440</div>
                  <div>
                    +91 974 744 7833
                  </div>
                </div>

              </div>
            </div>

            <div className="contact-email">
              <i className="ion-ios-email-outline"></i>
              <div className='flex justify-center'>
                <img src={EmailIcon} alt='EmailIcon' className='w-10 h-10' />
              </div>

              <div className='flex justify-center'>

                <div className='p-2'>
                  <div>Email</div>
                  <div
                  // href="mdivilto:info@luxuryvoyagewellness.life"
                  >
                   info@luxuryvoyagewellness.life
                  </div>

                </div>

              </div>
              
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <div className=' md:w-1/2 lg:w-1/2 sm:w-full '>
              <Modal />
            </div>

          </div>

        </div>
      </section>
    </div>

  )

}
