import React, { Component } from 'react'

const CommonLayout = ({ headerImage, heading, title, image1, contentTitle, content, image2, image3, content2, contentTitle2, content3, contentTitle3 }) => {

    return (
        <div className="wrapper font-Frank" style={{ background: 'white' }}>
            <div>
                <div className="header relative">
                    <img
                        src={headerImage}
                        className="background-Medical-spa"
                        style={{ width: "100%" }}
                        alt="background-image"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className="sm:text-xl md:text-7xl font-bold font-sans text-white">{heading}</h1>
                    </div>
                </div>

            </div>
            <div className="container" style={{ background: "white" }}>
                <header style={{ padding: 20 }}>
                    <div className='text-xl font-bold font-sans text-black'>{title}</div>
                </header>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-5 lg:mb-0 p-5">
                    <div className="col-lg-6">
                        <img className="img-fluid mb-3 lg:mb-0" src={image1} alt="" style={{ width: "100%" }} />
                    </div>
                    <div className="col-lg-6 items-center  flex">
                        <div className="featured-text text-center ">
                            <div className='font-bold font-sans text-black'>{contentTitle}</div>
                            <p className="text-black-50 mb-0 text-justify font-sans text-black">{content}</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-5">
                    <div className="col-lg-6">
                        <img className="img-fluid" src={image2} alt="" />
                    </div>
                    <div className="col-lg-6 items-center  flex">
                        <div className="text-center lg:text-left h-100 project">
                            <div className="flex h-full">
                                <div className="project-text w-full my-auto text-center lt">
                                    <div className="font-bold font-sans text-black" >{contentTitle2}</div>
                                    <p className="mb-0 text-white-50 text-justify font-sans text-black">{content2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-5">
                    <div className="col-lg-6 ">
                        <img className="img-fluid" src={image3} alt="" />
                    </div>
                    <div className="col-lg-6 order-first lg:order-none items-center  flex">
                        <div className="text-center lg:text-left h-100 project">
                            <div className="flex h-full">
                                <div className="project-text w-full my-auto text-center ">
                                    <div className="font-bold font-sans text-black" >{contentTitle3}</div>
                                    <p className="mb-0 text-white-50 text-justify font-sans text-black">{content3}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CommonLayout
