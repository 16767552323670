import React, { Component } from "react";
import image1 from "../images/ananda/ananda1.jpg";
import BRENNERS from "../images/BRENNERS/bren1.jpg";
import leela from "../images/leela/leela1.jpg";
import PalaceExterior from "../images/palace/PalaceExeterior.jpg";
import sha1 from "../images/sha-wellness/sha1.jpg";
import treatment5 from "../images/treatment5.jpg";
// import ScrollAnimation from "react-animate-on-scroll";
import hotel from "../images/hotel.png";

const Services = () => {

  const servicesData = [
    // {
    //   image: sha1,
    //   title: "SHA WELLNESS",
    //   link: "/sha-wellness",
    //   description:
    //     "Our mission at SHA is to produce a positive, substantial, and lasting change in your health and wellness, providing a state of complete balance during your unforgettable stay."
    // },
    {
      image: image1,
      title: "ANANDA HIMALAYA",
      link: "/ananda-himalaya",
      description:
        "Ananda is an award-winning luxury destination spa resort in India situated at the Himalayan foothills in Northern India. Ananda is surrounded by graceful Sal forests and overlooks the spiritual town of Rishikesh and the Ganges river valley."
    },
    // {
    //   image: BRENNERS,
    //   title: "BRENNERS PARK-HOTEL AND SPA",
    //   link: "/BRENNERS-PARK-HOTEL-AND-SPA",
    //   description:
    //     "Brenners Park-Hotel & Spa has been a place of relaxation and diversion for the travelling elite since 1872. Here guests can enjoy all that Baden-Baden."
    // },
    {
      image: leela,
      title: "LEELA RAVIZ",
      link: "/LEELA-RAVIZ",
      description:
        "The Raviz Group has set out on a course to present the traditions, culture and hospitality of Kerala to the visiting traveler on a platter."
    },
    {
      image: PalaceExterior,
      title: "PALACE RESORTS",
      link: "/PalaceResorts",
      description:
        "Our onsite amenities include indoor and outdoor whirlpools, a large oceanfront outdoor pool, exercise room, sauna, seasonal lounge, and much, much more. Our convenient location gives you and your family quick and easy access to all the fun and excitement."
    },
    {
      image: treatment5,
      title: "AYURVEDA TREATMENTS",
      link: "/Ayurveda",
      description:
        "Ayurveda has been carefully nurtured in Kerala for more than a thousand years. From specialised treatments to prescribed diets, it is an integral part of our culture. Ayurveda is a way of life to us and not simply a method to cure ailments."
    }
    // Add more objects for additional services following the same structure
  ];


  const ServiceCard = ({ image, title, link, description }) => {
    return (
      <div className="wow fadeInUp">
        <div className="about-col p-4 rounded-lg">
          <div className="img">
            <img src={image} alt="" className="h-48 w-full" />
            <div className="icon  ">
              <img
                style={{ maxWidth: "30px" }}
                className="ion-ios-speedometer-outline"
                src={hotel}
                alt=""
              />
            </div>
          </div>
          <h2 className="text-xl font-bold mt-4">
            <a href={link} className="text-blue-500 hover:underline font-Frank">
              {title}
            </a>
          </h2>
          <div className="text-gray-700 text-sm font-sans  text-justify">{description}</div>
        </div>
      </div>
    );
  };

  const TopServiceData = [
    {
      iconClass: "ion-ios-bookmarks-outline text-blue-500",
      title: "Ayurveda",
      colorClass: "text-white",
      description:
        "Ayurveda has been carefully nurtured in Kerala for more than a thousand years. From specialised treatments to prescribed diets, it is an integral part of our culture. Ayurveda is a way of life to us and not simply a method to cure ailments. Our land is blessed with several herbal medicines and these have played a crucial role in ensuring the growth of Ayurveda on our shores."
    },
    {
      iconClass: "ion-ios-stopwatch-outline",
      title: "Rejuvenation Therapy",
      colorClass: "text-white",
      description:
        "Among Ayurveda's most significant achievements is making the body strong and preventing the contraction of diseases. This process is done via rejuvenation therapy. Here the body is flushed clean of toxins using special herbs. This has proven to strengthen the immune system immensely and drastically improve the overall condition of the patient."
    },
    {
      iconClass: "ion-ios-heart-outline text-red-500",
      title: "Process of Treatment",
      colorClass: "text-white",
      description:
        "Ayurveda prescribes a certain rest period at the end of the treatment. Your body needs a certain amount of time to get used to the changes it has gone through. Most practitioners will provide a certain fixed diet that is streamlined to help with the recovery process. There are many Ayurvedic hospitals in the State alongside hotels and resorts that offer Ayurvedic treatment throughout the year."
    }
    // Add more objects for additional services following the same structure
  ];

  const TopServiceCard = ({ iconClass, title, colorClass, description }) => {
    return (
      <div className="lg:w-1/3 sm:w-full px-4 mb-8">
        <div className={`bg-black rounded-lg shadow-lg p-6 ${colorClass}`}>
          {/* <i className={`text-4xl ${iconClass}`}></i> */}
          <div className=" font-bold mt-4">
            <div className="text-xl hover:underline font-Frank">
              {title}
            </div>
          </div>
          <div className="mt-2  font-sans text-sm ">{description}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <section id="featured-services">

        <div class="container mx-auto px-4 font-Frank">
          <div class="flex flex-wrap -mx-4">

            {TopServiceData.map((service, index) => (
              <TopServiceCard key={index} {...service} />
            ))}

          </div>
        </div>


      </section>
      <section id="about">

        <div className="container mx-auto">
          <header className="text-center">
            <h3 className="text-3xl font-bold font-Frank">RESORTS</h3>
          </header>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
            {servicesData.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>

        </div>


      </section>
    </div>
  );
}
export default Services;
